import { ISO_DATE_FORMAT } from "./constants";
import { format, parseISO } from "date-fns";
import styled from "styled-components/macro";
import { Box } from "@material-ui/core";

export const dateFormatter = (dateInput, formatString) => {
  // Parse the input into a Date object if it's a string
  const date = typeof dateInput === "string" ? parseISO(dateInput) : dateInput;

  // Format the Date object according to the format string
  return format(date, formatString);
};

const monthLookup = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const transformDataForBarChart = (data) => {
  if (!data.length) return [];

  return data.map((item) => {
    return {
      Month: monthLookup[item.dmonth],
      "10 Year Average": item.monthly_normal_precip,
      [`${item.dyear} Precip`]: item.monthly_greeley_precip,
      "Irrigation Water Requirement (IWR)": item.iwr_in,
      units: item.precip_units,
      year: item.dyear,
    };
  });
};

const isStartOfMonth = (date) => parseISO(date).getDate() === 1;

const extractYear = (date) => parseISO(date).getFullYear();
export const transformDataForLineChart = (data) => {
  if (!data.length) return { series: [], ticks: [] };

  let tickValues = new Set();

  const series = data.reduce(
    (acc, { collect_date, usc_avgtemp, usc_normtemp, units }) => {
      const formattedDate = dateFormatter(collect_date, ISO_DATE_FORMAT);
      if (isStartOfMonth(collect_date)) {
        tickValues.add(formattedDate);
      }

      acc[0].data.push({ x: formattedDate, y: usc_normtemp, units });
      acc[1].data.push({ x: formattedDate, y: usc_avgtemp, units });
      return acc;
    },
    [
      { id: "Historical Average Temperature", color: "#C9C9C9", data: [] },
      {
        id: `${extractYear(data[0]?.collect_date)} Avg Temp`,
        color: "#5CA740",
        data: [],
      },
    ]
  );

  return { series, ticks: [...tickValues] };
};

export function getContrastYIQ(rgbColor) {
  const rgbValues = rgbColor.match(/\d+/g).map(Number); // Extract RGB values
  const [r, g, b] = rgbValues;
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

export const ChartWrapper = styled(Box)`
  height: ${({ height }) => height || "400px"};
  width: 100%;
`;

export const ChartTooltipContainer = styled(Box)`
  color: ${({ color }) => color};
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  padding: 10px;
  border-radius: 5px;
  max-width: 100%;
  white-space: nowrap;
`;
