export const DEFAULT_MANUAL_DATA_ENTRY_STATE = {
  ndx: null,
  accounting_year: null,
  actual_stor_bod_af: null,
  meterread_out_bod_gal: null,
  calc_stor_bod_af: null,
  deliv_35th_cfs: null,
  deliv_boyd_cfs: null,
  gic_5_8ths_cfs: null,
  newcache_cfs: null,
  wssc_cfs: null,
  leprino_cfs: null,
  overlandtrl_cfs: null,
  foreign_cfs: null,
  otr_balance_af: null,
  notes: null,
};
