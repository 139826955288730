import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { DEFAULT_DATA_ADJUSTMENT_STATE } from "./constants";
import { useState } from "react";
import useFetchData from "../../../hooks/useFetchData";

export const useBasicWtpDataAdjustmentsInfo = (selectedDataAdjustment) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useState(DEFAULT_DATA_ADJUSTMENT_STATE);
  const [isDirty, setIsDirty] = useState(false);

  const handleUpdateState = (name, value) => {
    if (!isDirty) setIsDirty(true);
    setEditorState((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const handleResetState = () => {
    setIsDirty(false);
    if (selectedDataAdjustment) {
      setEditorState(data);
    } else {
      setEditorState(DEFAULT_DATA_ADJUSTMENT_STATE);
    }
  };

  const { data, isFetching, error } = useQuery(
    ["data-overrides-wtp", selectedDataAdjustment],
    async () => {
      if (!selectedDataAdjustment) {
        setIsDirty(false);
        setEditorState(DEFAULT_DATA_ADJUSTMENT_STATE);
        return {};
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-overrides-wtp/${selectedDataAdjustment}`,
          { headers }
        );
        setIsDirty(false);
        setEditorState(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [parametersLookup] = useFetchData(
    "list-parameters-wtpoverrides",
    [],
    true
  );
  const [locationsLookup] = useFetchData(
    "list-locations-wtpoverrides",
    [],
    true
  );

  return {
    data,
    isLoading: isFetching,
    error,
    isDirty,
    lookups: {
      parameters: parametersLookup,
      locations: locationsLookup,
    },
    handleUpdateState,
    handleResetState,
    state: editorState,
    setter: setEditorState,
  };
};
