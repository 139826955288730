import { useQuery } from "react-query";
import axios from "axios";
import { DEFAULT_FILTERS } from "./constants";
import { useCallback, useState } from "react";
import useFetchData from "../../../../hooks/useFetchData";

export const useRiverCalls = () => {
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const handleFilterChange = useCallback((name, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  }, []);

  const [waterSourcesLookup] = useFetchData("river-calls/water-sources");

  const { data, error, isFetching } = useQuery(
    [`river-calls/${filters.waterSource}`],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/river-calls/table/${filters.waterSource}`
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  return {
    waterSourcesLookup,
    data,
    error,
    isLoading: isFetching,
    handleFilterChange,
    filters,
    setFilters,
  };
};
