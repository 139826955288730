export const DEFAULT_WATER_SOURCE_STATE = {
  wadt_source_ndx: null,
  wadt_source_name: null,
  wadt_source_abbrev: null,
  wadt_class_ndx: "",
  reason_for_exclusion: null,
  assoc_water_source_ndx: [],
  structure_wd: "",
  structure_id: "",
  assoc_supply_type_ndx: [],
  assoc_wr_type_ndx: [],
  created_timestamp: null,
  last_updated_timestamp: null,
  yieldhdgt_afpsh: null,
  yieldcu_afpsh: null,
  yield_notes: null,
  cost_per_share: null,
  cost_per_af: null,
  integration_cost: null,
  o_and_m_cost: null,
  willingness_of_ower_to_sell: null,
  risk_of_price_escalation: null,
  shares_total: null,
  shares_not_changed: null,
  shares_greeley_owned: null,
  competition_level_ndx: null,
  availability_and_competition_notes: null,
  yield_under_shifted_hydro: null,
  seniority_and_reliability: null,
  vulerability_to_change: null,
  integration_into_existing_system: null,
  integration_into_terry_ranch: null,
  time_to_implement: null,
  legal_complexity: null,
  ditch_reservoir_company_considerations: null,
  water_right_operational_flexibility: null,
};
