import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { DEFAULT_FILTERS, QUERY_ISO_DATE_FORMAT } from "./constants";
import { useCallback, useMemo, useState } from "react";
import { format } from "date-fns";
import { prepareChartData } from "./utils";

export const useDailyProduction = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const handleFilterChange = useCallback((name, value) => {
    setFilters((prevFilters) => {
      return { ...prevFilters, [name]: value };
    });
  }, []);

  const formattedDates = useMemo(() => {
    return {
      startDate: format(new Date(filters.startDate), QUERY_ISO_DATE_FORMAT),
      endDate: format(new Date(filters.endDate), QUERY_ISO_DATE_FORMAT),
    };
  }, [filters.startDate, filters.endDate]);

  const fetchData = async () => {
    const token = await getAccessTokenSilently();
    const endpoint = `${process.env.REACT_APP_ENDPOINT}/api/dashboard-daily-production`;
    const url = `${endpoint}/${formattedDates.startDate}/${formattedDates.endDate}`;
    const headers = { Authorization: `Bearer ${token}` };

    try {
      const { data } = await axios.get(url, { headers });
      return data;
    } catch (err) {
      console.error("Error fetching data:", err);
      throw err;
    }
  };

  const { data, error, isFetching } = useQuery(
    ["dashboard-daily-production", filters.startDate, filters.endDate],
    fetchData,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  const chartData = useMemo(() => {
    return prepareChartData(data);
  }, [data]);

  return {
    chartData,
    data,
    isLoading: isFetching,
    error,
    handleFilterChange,
    filters,
    setFilters,
  };
};
