import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { DEFAULT_MANUAL_DATA_ENTRY_STATE } from "./constants";
import { useState } from "react";

export const useBasicManualDataEntryInfo = (selectedManualDataEntry) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useState(
    DEFAULT_MANUAL_DATA_ENTRY_STATE
  );
  const [isDirty, setIsDirty] = useState(false);

  const handleUpdateState = (name, value) => {
    if (!isDirty) setIsDirty(true);
    setEditorState((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const handleResetState = () => {
    setIsDirty(false);
    if (selectedManualDataEntry) {
      setEditorState(data);
    } else {
      setEditorState(DEFAULT_MANUAL_DATA_ENTRY_STATE);
    }
  };

  const { data, isFetching, error } = useQuery(
    ["data-pp-accounting1031-values", selectedManualDataEntry],
    async () => {
      if (!selectedManualDataEntry) {
        setIsDirty(false);
        setEditorState(DEFAULT_MANUAL_DATA_ENTRY_STATE);
        return {};
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-pp-accounting1031-values/${selectedManualDataEntry}`,
          { headers }
        );
        setIsDirty(false);
        setEditorState(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    isLoading: isFetching,
    error,
    isDirty,
    handleUpdateState,
    handleResetState,
    state: editorState,
    setter: setEditorState,
  };
};
