import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useState } from "react";

export const useManualDataEntry = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedManualDataEntry, setSelectedManualDataEntry] = useState(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["data_pp_accounting1031_values"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-pp-accounting1031-values`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
  const COLUMNS = [
    {
      title: "Accounting Year",
      field: "accounting_year",
    },
    {
      title: "BOD Actual Storage Content",
      field: "actual_stor_bod_af",
    },
    {
      title: "BOD Outflow Meter Reading",
      field: "meterread_out_bod_gal",
    },
    {
      title: "BOD Calculated Storage Content",
      field: "calc_stor_bod_af",
    },
    {
      title: "Owe the River Account Balance",
      field: "otr_balance_af",
    },
    {
      title: "Measured Deliveries into Poudre Ponds from 35th Ave Ditch",
      field: "deliv_35th_cfs",
    },
    {
      title: "Measured Deliveries into Poudre Ponds from Boyd Freeman",
      field: "deliv_boyd_cfs",
    },
    {
      title: "GIC (5/8ths Deliveries)",
      field: "gic_5_8ths_cfs",
    },
    {
      title: "Newcache",
      field: "newcache_cfs",
    },
    {
      title: "WSSC",
      field: "wssc_cfs",
    },
    {
      title: "Leprino",
      field: "leprino_cfs",
    },
    {
      title: "Overland Trail",
      field: "overlandtrl_cfs",
    },
    {
      title: "Other Foreign",
      field: "foreign_cfs",
    },
    {
      title: "Storage 1999 Poudre Ponds Right",
      field: "stor_deliv_1999_af",
    },
    {
      title: "Storage 2006 Poudre Ponds Right",
      field: "stor_deliv_2006_af",
    },
    {
      title: "Notes",
      field: "notes",
    },
  ];

  return {
    selectedManualDataEntry,
    setSelectedManualDataEntry,
    data,
    isFetching,
    error,
    refetch,
    COLUMNS,
  };
};
