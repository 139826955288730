import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useMemo, useState } from "react";
import useFetchData from "../../../../hooks/useFetchData";

export const useAcquisitionsAndOpportunities = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedOpportunity, setSelectedOpportunity] = useState(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["data-opportunities"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-opportunities`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [sourcesLookup] = useFetchData("ui-lookup-wadt-sources", [], true);
  const sourcesLookupForTable = useMemo(() => {
    let converted = {};
    if (sourcesLookup?.length > 0) {
      sourcesLookup.forEach((item) => {
        converted[item.wadt_source_ndx] = item.wadt_source_name;
      });
    }
    return converted;
  }, [sourcesLookup]);

  const [typeLookup] = useFetchData("list-opp-type", [], true);
  const typeLookupForTable = useMemo(() => {
    let converted = {};
    if (typeLookup?.length > 0) {
      typeLookup.forEach((item) => {
        converted[item.opp_type_ndx] = item.opp_type_desc;
      });
    }
    return converted;
  }, [typeLookup]);

  const [recordStatusLookup] = useFetchData("list-opp-record-status", [], true);
  const recordStatusLookupForTable = useMemo(() => {
    let converted = {};
    if (recordStatusLookup?.length > 0) {
      recordStatusLookup.forEach((item) => {
        converted[item.opp_record_status_ndx] = item.opp_record_status_desc;
      });
    }
    return converted;
  }, [recordStatusLookup]);

  const [recommendationLookup] = useFetchData(
    "list-opp-recommendation",
    [],
    true
  );
  const recommendationLookupForTable = useMemo(() => {
    let converted = {};
    if (recommendationLookup?.length > 0) {
      recommendationLookup.forEach((item) => {
        converted[item.opp_recommendation_ndx] = item.opp_recommendation_desc;
      });
    }
    return converted;
  }, [recommendationLookup]);

  const [decisionLookup] = useFetchData("list-opp-decision", [], true);
  const decisionLookupForTable = useMemo(() => {
    let converted = {};
    if (decisionLookup?.length > 0) {
      decisionLookup.forEach((item) => {
        converted[item.opp_decision_ndx] = item.opp_decision_desc;
      });
    }
    return converted;
  }, [decisionLookup]);

  const SUMMARY_TABLE_COLUMNS = [
    {
      title: "Opportunity Name",
      field: "opportunity_name",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Created Date",
      field: "created_date",
      type: "date",
    },
    {
      title: "WADT Water Source",
      field: "wadt_source_ndx",
      lookup: sourcesLookupForTable,
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
    },
    {
      title: "Type",
      field: "opp_type_ndx",
      lookup: typeLookupForTable,
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Status",
      field: "opp_record_status_ndx",
      lookup: recordStatusLookupForTable,
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "WR Recommendation",
      field: "opp_recommendation_ndx",
      lookup: recommendationLookupForTable,
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Board DevisionDecision",
      field: "opp_decision_ndx",
      lookup: decisionLookupForTable,
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Last Updated",
      field: "last_updated",
      type: "date",
    },
  ];

  return {
    selectedOpportunity,
    setSelectedOpportunity,
    data,
    isFetching,
    error,
    refetch,
    SUMMARY_TABLE_COLUMNS,
  };
};
