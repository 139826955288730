import { subDays, format } from "date-fns";
import { formatDateForTooltip } from "./utils";
export const ISO_DATE_FORMAT = "MM/dd/yyyy";
export const QUERY_ISO_DATE_FORMAT = "yyyy-MM-dd";

export const DEFAULT_FILTERS = {
  startDate: format(subDays(new Date(), 30), ISO_DATE_FORMAT),
  endDate: format(new Date(), ISO_DATE_FORMAT),
};

export const GRAPH_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
      title: {
        display: true,
        text: "Millions of Gallons per Day (MGD)",
      },
    },
    // y1: {
    //   type: "linear",
    //   display: true,
    //   position: "right",
    //   grid: {
    //     drawOnChartArea: false,
    //   },
    //   title: {
    //     display: true,
    //     text: "Irrigation Water Requirement (Inches)",
    //   },
    // },
    x: {
      type: "time",
      time: {
        unit: "day",
        displayFormats: {
          day: "MMM D",
        },
      },
      title: {
        display: false,
        text: "Date",
      },
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          label += context.parsed.y;

          if (context.dataset.yAxisID === "y") {
            label += " MGD";
          }
          // else if (context.dataset.yAxisID === "y1") {
          //   label += " Inches";
          // }

          return label;
        },
        title: function (context) {
          if (context[0]) {
            const date = new Date(context[0].label);
            return formatDateForTooltip(date);
          }
          return "";
        },
      },
    },
  },
};
