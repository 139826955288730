import { ISO_DATE_FORMAT } from "./constants";
import { format } from "date-fns";

export function prepareChartData(data) {
  if (!data?.length) return [];

  const labels = [];
  const bellvueData = [];
  const boydData = [];
  // const iwrData = [];

  data.forEach((item) => {
    labels.push(item.collect_date);
    bellvueData.push(item.bellvue);
    boydData.push(item.boyd);
    // iwrData.push(item.iwr);
  });

  const chartData = {
    labels: labels,
    datasets: [
      // keeping this as a reference to a two axis chart
      // {
      //   label: "Irr Water Req",
      //   data: iwrData,
      //   borderColor: "rgba(255, 206, 0, 0.8)",
      //   backgroundColor: "rgba(0, 0, 0, 0)",
      //   yAxisID: "y1",
      //   type: "line",
      //   borderWidth: 2,
      //   pointRadius: 4,
      //   pointBackgroundColor: "rgba(255, 206, 0, 0.8)",
      //   fill: false,
      // },
      {
        label: "Bellvue",
        data: bellvueData,
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        yAxisID: "y",
        type: "bar",
      },
      {
        label: "Boyd",
        data: boydData,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y",
        type: "bar",
      },
    ],
  };

  return chartData;
}

export const formatDateForTooltip = (dateString) => {
  return format(dateString, ISO_DATE_FORMAT);
};
