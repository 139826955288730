import { useMutation, useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { DEFAULT_VALUES, FILENAME, VALIDATION_SCHEMA } from "./constants";
import axios from "axios";
import {
  filterDataWithCriteria,
  generateAuthHeaders,
  hasEmptyValues,
  triggerDownload,
  updateFilteredSelections,
  useCustomQuery,
} from "./utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useRef, useState } from "react";
import { useApp } from "../../../../AppProvider";

export const useQueryAndDownload = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();
  const formMethods = useForm({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: DEFAULT_VALUES,
  });

  const { watch, getValues, setValue } = formMethods;

  // trigger for count query
  const formValues = watch([
    "timestep",
    "startDate",
    "endDate",
    "waterbodies",
    "locationTypes",
    "parameters",
    "locations",
  ]);
  const formEligibleForFetching = !hasEmptyValues(getValues());

  const [currentCount, setCurrentCount] = useState(null);

  useEffect(() => {
    if (!formEligibleForFetching) {
      setCurrentCount(null);
    }
  }, [formEligibleForFetching]);

  // if a new count request is made, the previous one is cancelled
  const cancelTokenSourceRef = useRef(axios.CancelToken.source());
  const fetchDataCount = async () => {
    const formData = getValues();
    const url = `${process.env.REACT_APP_ENDPOINT}/api/query-and-download/count-data`;
    const headers = await generateAuthHeaders(
      getAccessTokenSilently,
      isAuthenticated
    );

    cancelTokenSourceRef.current.cancel("Cancelling previous request");
    cancelTokenSourceRef.current = axios.CancelToken.source();

    try {
      const { data } = await axios.post(url, formData, {
        headers,
        cancelToken: cancelTokenSourceRef.current.token,
      });
      return data.count;
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error("Fetch error:", error);
      }
      throw error;
    }
  };

  const { isFetching: isLoadingCurrentCount } = useQuery(
    ["data-count", isAuthenticated, formValues],
    fetchDataCount,
    {
      enabled: formEligibleForFetching,
      retry: false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCurrentCount(data);
      },
      onError: (error) => {
        setCurrentCount(null);
        console.error("Error fetching count:", error);
      },
    }
  );

  const { mutate, isLoading, error } = useMutation(
    async (formData) => {
      const url = `${process.env.REACT_APP_ENDPOINT}/api/query-and-download/${formData.timestep}`;

      const headers = await generateAuthHeaders(
        getAccessTokenSilently,
        isAuthenticated
      );

      try {
        const response = await axios.post(url, formData, {
          headers,
          responseType: "blob",
        });

        return { csvContent: response.data, filename: FILENAME };
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: ({ csvContent, filename }) => {
        triggerDownload(csvContent, filename);
        doToast("success", "CSV file successfully added to download folder.");
      },
      onError: (err) => {
        doToast("error", "Failed to download CSV file.");
        console.error(err);
      },
    }
  );

  const onSubmit = (data) => {
    mutate(data);
  };

  const timestepsQuery = useCustomQuery(
    ["q-d-list-timesteps"],
    "query-and-download/q-d-list-timesteps"
  );
  const dataSourcesQuery = useCustomQuery(
    ["q-d-list-data-sources"],
    "query-and-download/q-d-list-data-sources"
  );
  const waterbodiesQuery = useCustomQuery(
    ["q-d-list-waterbodies"],
    "query-and-download/q-d-list-waterbodies"
  );
  const locationTypesQuery = useCustomQuery(
    ["q-d-list-location-types"],
    "query-and-download/q-d-list-location-types"
  );
  const parametersQuery = useCustomQuery(
    ["q-d-list-parameters"],
    "query-and-download/q-d-list-parameters"
  );
  const locationsQuery = useCustomQuery(
    ["q-d-list-locations", isAuthenticated],
    "query-and-download/q-d-list-locations"
  );

  const dataSources = watch("dataSources");
  const waterbodies = watch("waterbodies");
  const locationTypes = watch("locationTypes");

  const parameters = watch("parameters");

  const filteredParameters = useMemo(() => {
    const criteria = {
      src_data_ndx: dataSources,
    };
    return filterDataWithCriteria(parametersQuery.data, criteria);
  }, [parametersQuery.data, dataSources]);

  const filteredLocations = useMemo(() => {
    const criteria = {
      watershed_ndx: waterbodies,
      location_type_ndx: locationTypes,
      src_data_ndx: dataSources,
    };
    return filterDataWithCriteria(locationsQuery.data, criteria, parameters);
  }, [
    locationsQuery.data,
    waterbodies,
    locationTypes,
    dataSources,
    parameters,
  ]);

  useEffect(() => {
    updateFilteredSelections(
      "parameters",
      filteredParameters,
      (item) => item.parameter_ndx,
      getValues,
      setValue
    );
    updateFilteredSelections(
      "locations",
      filteredLocations,
      (item) => item.location_ndx,
      getValues,
      setValue
    );
  }, [filteredLocations, filteredParameters, getValues, setValue]);

  const inputs = {
    waterbodies: {
      data: waterbodiesQuery.data || [],
      isLoading: waterbodiesQuery.isFetching,
      error: waterbodiesQuery.error,
    },
    dataSources: {
      data: dataSourcesQuery.data || [],
      isLoading: dataSourcesQuery.isFetching,
      error: dataSourcesQuery.error,
    },
    locations: {
      data: filteredLocations || [],
      isLoading: locationsQuery.isFetching,
      error: locationsQuery.error,
    },
    locationTypes: {
      data: locationTypesQuery.data || [],
      isLoading: locationTypesQuery.isFetching,
      error: locationTypesQuery.error,
    },
    parameters: {
      data: filteredParameters || [],
      isLoading: parametersQuery.isFetching,
      error: parametersQuery.error,
    },
    timesteps: {
      data: timestepsQuery.data || [],
      isLoading: timestepsQuery.isFetching,
      error: timestepsQuery.error,
    },
  };

  return {
    formMethods,
    onSubmit,
    isLoading,
    error: error,
    inputs,
    currentCount,
    isLoadingCurrentCount,
  };
};

export default useQueryAndDownload;
