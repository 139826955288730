import React from "react";
import Table from "../../../components/Table";
import { useQuery } from "react-query";
import axios from "axios";

const CumulativePrecipitationTable = () => {
  const { data, isFetching, error } = useQuery(
    ["/api/dashboard-cumulative-precip"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/dashboard-cumulative-precip`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const columns = [
    {
      title: "",
      field: "col1",
    },
    {
      title: "",
      field: "col2",
    },
  ];

  if (error) return "An error has occurred: " + error.message;

  return (
    <Table
      label="Cumulative Precipitation"
      isLoading={isFetching}
      columns={columns}
      data={data}
      height="100%"
      options={{
        search: false,
        paging: false,
        toolbar: false,
        header: false,
        padding: "default",
      }}
    />
  );
};

export default CumulativePrecipitationTable;
