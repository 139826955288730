import React from "react";
import { useQuery } from "react-query";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useApp } from "../../../AppProvider";
import Panel from "../../../components/panels/Panel";
import Table from "../../../components/Table";
import { dateFormatter } from "../ClimateCenter/utils";

const TableWrapper = styled.div`
  width: 100%;
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

//388px
const NorthernData = () => {
  const { currentUser } = useApp();

  const { data, isFetching, error } = useQuery(
    ["/api/display-northern-daily-for-table"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/display-northern-daily-for-table`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const tabColumns = [
    {
      title: "Data Source",
      field: "datasrc",
      filtering: false,
    },
    {
      title: "Location",
      field: "location_name",
      filtering: false,
    },
    {
      title: "Date",
      field: "collect_date",
      type: "date",
      render: (rowData) => {
        return dateFormatter(rowData.collect_date, "MM/dd/yyyy");
      },
      customFilterAndSearch: (filterValue, rowData) => {
        // corrects offset caused by the MUI date picker filter
        const rowDate = rowData.collect_date;
        const filterDate = filterValue.toISOString().split("T")[0];

        return rowDate === filterDate;
      },
    },
    {
      title: "ET, Grass (inches)",
      field: "et_grass_in",
      filtering: false,
    },
    {
      title: "Precipitation, Water-bucket (inches)",
      field: "precip_wb_in",
      filtering: false,
    },
    {
      title: "Irrigation Water Requirement (inches)",
      field: "iwr_in",
      filtering: false,
    },
  ];

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <Helmet title="Northern Colorado Water Conservancy District" />
      <Typography variant="h3" gutterBottom display="inline">
        Northern Colorado Water Conservancy District
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={currentUser?.home || "/"}>
          Dashboard
        </Link>
        <Typography>Northern Data</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <Panel>
        <TableWrapper>
          <Table
            label="Station 'Greeley West' (971) Daily Evapotranspiration (ET)"
            isLoading={isFetching}
            columns={tabColumns}
            data={data}
            height="calc(100vh - 485px)"
            pageSize={60}
            options={{
              filtering: true,
              search: false,
              minBodyHeight: "400px",
              showTitle: true,
            }}
          />
        </TableWrapper>
      </Panel>
    </>
  );
};
export default NorthernData;
