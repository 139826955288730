import React, { useCallback, useRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Link,
  Paper,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import MaterialTable from "material-table";
import CopyIcon from "@material-ui/icons/FileCopy";
import { ExpandMore } from "@material-ui/icons";

import { NavLink } from "react-router-dom";
import { useApp } from "../../../../AppProvider";

import {
  copyToClipboard,
  dateFormatter,
  scrollToTarget,
} from "../../../../utils";
import BasicWaterSourceInfo from "./BasicWaterSourceInfo";
import { useWaterSources } from "./useWaterSources";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

function EditWaterSourcesManagement() {
  const {
    selectedWaterSource,
    setSelectedWaterSource,
    data,
    isFetching,
    error,
    refetch,
    WATER_SOURCES_TABLE_COLUMNS,
  } = useWaterSources();
  const { doToast, currentUser } = useApp();
  const readSaveRef = useRef(null);

  const handleScrollToReadSave = useCallback(() => {
    setTimeout(() => scrollToTarget(readSaveRef), 1000);
  }, []);

  if (error) return "An error has occurred: " + error.message;
  return (
    <React.Fragment>
      <Helmet title="WADT Water Source Details Report" />
      <Typography variant="h3" gutterBottom display="inline">
        WADT Water Source Details Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={currentUser?.home || "/"}>
          Dashboard
        </Link>
        <Typography>WADT Water Source Details Report</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Box>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box style={{ width: "100%" }}>
              <Typography variant="h3">Water Sources Selection</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <TableWrapper>
              <MaterialTable
                id="WADT Water Source Details Report"
                title={`WADT Water Source Details Report ${dateFormatter(
                  new Date(),
                  "MM/DD/YYYY, h:mm A"
                )}`}
                loading={isFetching || !data}
                columns={WATER_SOURCES_TABLE_COLUMNS}
                data={data}
                localization={{
                  toolbar: { searchPlaceholder: "Water Sources Selection" },
                }}
                // editable={{
                //   onRowDelete: handleDelete,
                // }}
                actions={[
                  {
                    icon: CopyIcon,
                    tooltip: "Copy Data",
                    isFreeAction: true,
                    onClick: () => {
                      try {
                        copyToClipboard(data, WATER_SOURCES_TABLE_COLUMNS, () =>
                          doToast(
                            "success",
                            "Data was copied to your clipboard."
                          )
                        );
                      } catch (error) {
                        const message =
                          error?.message ?? "Something went wrong";
                        doToast("error", message);
                      }
                    },
                  },
                  () => ({
                    icon: "edit",
                    tooltip: "Edit",
                    onClick: (event, rowData) => {
                      setSelectedWaterSource(rowData?.wadt_source_ndx);
                      handleScrollToReadSave();
                    },
                  }),
                  {
                    icon: "add_box",
                    tooltip: "Add",
                    isFreeAction: true,
                    onClick: () => {
                      setSelectedWaterSource(0);
                      handleScrollToReadSave();
                    },
                  },
                ]}
                options={{
                  filtering: true,
                  emptyRowsWhenPaging: false,
                  showTitle: false,
                  columnsButton: true,
                  exportButton: true,
                  exportAllData: true,
                  addRowPosition: "first",
                  pageSize: 50,
                  pageSizeOptions: [10, 30, 50, 100, 200],
                  padding: "dense",
                  searchFieldAlignment: "left",
                  minBodyHeight: "250px",
                  maxBodyHeight:
                    "calc(100vh - 64px - 48px - 106px - 48px - 64px - 204px)",
                }}
              />
            </TableWrapper>
          </AccordionDetails>
        </Accordion>

        <Box
          style={{
            display:
              selectedWaterSource || selectedWaterSource === 0
                ? "block"
                : "none",
          }}
        >
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box pv={4} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4">
                  {selectedWaterSource === 0
                    ? "Creating New Water Source"
                    : "Editing Existing Water Source"}
                </Typography>
              </Box>

              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedWaterSource(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
          <div ref={readSaveRef}>
            <BasicWaterSourceInfo
              selectedWaterSource={selectedWaterSource}
              setSelectedWaterSource={setSelectedWaterSource}
              refetchWaterSources={refetch}
            />
          </div>
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box pv={4} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4">
                  {selectedWaterSource === 0
                    ? "Creating New Water Source"
                    : "Editing Existing Water Source"}
                </Typography>
              </Box>

              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedWaterSource(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default EditWaterSourcesManagement;
