import React, { useMemo, useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link,
  Paper,
  Typography as MuiTypography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { useApp } from "../../../AppProvider";
import { useDailyProduction } from "./useDailyProduction";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../../components/Loader";
import Table from "../../../components/Table";
import Button from "@material-ui/core/Button";
import { Bar } from "react-chartjs-2";
import { GRAPH_OPTIONS } from "./constants";

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled(Box)`
  width: 100%;
`;

const ChartWrapper = styled(Box)`
  height: 500px;
  width: 100%;
`;

const DailyProduction = () => {
  const { currentUser } = useApp();
  const scrollTargetRef = useRef(null);

  const scrollToTarget = () => {
    scrollTargetRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const { chartData, data, isLoading, error, handleFilterChange, filters } =
    useDailyProduction();

  const COLUMNS = useMemo(
    () => [
      {
        title: "Date",
        field: "collect_date",
      },
      { title: "Bellvue", field: "bellvue" },
      {
        title: "Boyd",
        field: "boyd",
      },
      { title: "Units", field: "units" },
      // {
      //   title: "Irr Water Req",
      //   field: "iwr",
      // },
      // { title: "IWR Units", field: "iwr_units" },
    ],
    [data] //eslint-disable-line
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      <Helmet title="WTP Production" />
      <Typography variant="h3" gutterBottom display="inline">
        WTP Production
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={currentUser?.home || "/"}>
          Dashboard
        </Link>
        <Typography>WTP Production</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="options"
          id="options"
        >
          <Typography variant="h4">Options</Typography>
        </AccordionSummary>
        <Paper>
          <AccordionDetails>
            <Grid container spacing={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} sm={5}>
                  <DatePicker
                    fullWidth
                    value={filters.startDate}
                    onChange={(date) => handleFilterChange("startDate", date)}
                    id="start-date"
                    variant="inline"
                    format="LLLL do, yyyy"
                    autoOk
                    inputVariant="outlined"
                    label="Start Date"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <DatePicker
                    fullWidth
                    value={filters.endDate}
                    onChange={(date) => handleFilterChange("endDate", date)}
                    id="end-date"
                    variant="inline"
                    format="LLLL do, yyyy"
                    autoOk
                    inputVariant="outlined"
                    label="End Date"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={scrollToTarget}
                    disabled={!data?.length || isLoading}
                  >
                    Scroll to Table
                  </Button>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </AccordionDetails>
        </Paper>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="options"
          id="options"
        >
          <Typography variant="h4">
            Daily Water Treatment Plant Production
          </Typography>
        </AccordionSummary>
        <Paper>
          {isLoading ? (
            <Box mb={5}>
              <Loader />
            </Box>
          ) : !data?.length ? (
            <Typography style={{ textAlign: "center" }} mb={5}>
              No records available for this query. Please try again.
            </Typography>
          ) : (
            <AccordionDetails>
              <ChartWrapper>
                <Bar data={chartData} options={GRAPH_OPTIONS} />
              </ChartWrapper>
            </AccordionDetails>
          )}
        </Paper>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="table-content"
          id="table-header"
        >
          <Typography variant="h4">Table</Typography>
        </AccordionSummary>
        <Paper>
          <AccordionDetails>
            <TableWrapper ref={scrollTargetRef}>
              <Table
                isLoading={isLoading}
                label="Daily Water Treatment Plant Production"
                columns={COLUMNS}
                data={data}
                height="600px"
                options={{ search: false, width: "100%" }}
                pageSize={60}
              />
            </TableWrapper>
          </AccordionDetails>
        </Paper>
      </Accordion>
    </>
  );
};
export default DailyProduction;
