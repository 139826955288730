export const sClassColorMap = {
  "Class 1": "LightBlue",
  "Class 2": "CornflowerBlue",
  "Class 3": "LightGreen",
  Excluded: "LightSalmon",
};

export const generalColorMap = {
  Low: "SkyBlue",
  Moderate: "LightGreen",
  High: "LightSalmon",
  Unknown: "Gray",
};

export function getColorForValue(value, colorMap) {
  return colorMap[value] || "white";
}

// Determine the text color based on the background color
export function getTextColor(background) {
  if (background === "white") return "black";
  const brightness =
    parseInt(background.slice(1), 16) > 0xffffff / 2 ? "black" : "white";
  return brightness;
}

export const DEFAULT_WATER_SOURCE_STATE = {
  wadt_source_ndx: null,
  wadt_source_abbrev: null,
  s_class: null,
  ownership: null,
  availability: null,
  s_competition: null,
  s_potability: null,
  s_wr_type: null,
  yieldcu_afpsh: null,
  cost_per_af: null,
  wadt_source_name: null,
  diversion_name_fav: null,
  district: null,
  reason_for_exclusion: null,
  potability: null,
  water_right_type: null,
  water_source: null,
  yieldhdgt_afpsh: null,
  yield_notes: null,
  cost_per_share: null,
  integration_cost: null,
  o_and_m_cost: null,
  shares_total: null,
  shares_not_changed: null,
  shares_greeley_owned: null,
  competition: null,
  competition_notes: null,
  willingness_of_ower_to_sell: null,
  risk_of_price_escalation: null,
  yield_under_shifted_hydro: null,
  seniority_and_reliability: null,
  vulnerability_to_change: null,
  integration_into_existing_system: null,
  integration_into_terry_ranch: null,
  time_to_implement: null,
  legal_complexity: null,
  ditch_reservoir_company_considerations: null,
  water_right_operational_flexibility: null,
  wadt_class_ndx: null,
};
