export const BASEMAP_STYLES = [
  {
    style: "outdoors-v11",
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11",
  },
  {
    style: "streets-v11",
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
  },
  {
    style: "satellite-streets-v11",
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  {
    style: "light-v10",
    name: "Light",
    url: "mapbox://styles/mapbox/light-v10",
  },
  {
    style: "dark-v10",
    name: "Dark",
    url: "mapbox://styles/mapbox/dark-v10",
  },
];

export const DEFAULT_MAP_CENTER = [-104.93433, 40.48672];

export const INIT_MAP_CONFIG = {
  style: BASEMAP_STYLES[0].url,
  center: DEFAULT_MAP_CENTER,
  zoom: 9.7,
  preserveDrawingBuffer: true,
};

export const WELLS_LAYER_ID = "greeley-water-sources-fill";
export const WELLS_LABELS_LAYER_ID = "greeley-locations-symbol";
export const INIT_FILTER_VALUES = {
  classes: {
    label: "Classes",
    name: "classes",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "wadt_class_name",
    options: [],
    type: "multi-select",
    value: [],
  },

  structures: {
    name: "structures",
    label: "Structure",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "diversion_name",
    options: [],
    type: "multi-select-array",
    value: [],
  },
  waterSources: {
    name: "waterSources",
    label: "Water Source",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "water_src",
    options: [],
    type: "multi-select-array",
    value: [],
  },
};

const layerId = "greeley-water-sources-fill";
export const INIT_STYLE_VALUES = {
  classes: {
    id: "classes",
    layerId,
    layerFieldName: "wadt_class_name",
    name: "Classes",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "fill-color": [
        "match",
        ["get", "wadt_class_name"],
        ["Class 1 - Aggressive Acquisition"],
        "#A6CEE3",
        ["Class 2 - Active Acquisition"],
        "#1F78B4",
        ["Class 3 - Passive Acquisition"],
        "#B2DF8A",
        ["Unclassified"],
        "#FB9A99",
        "#fff",
      ],
    },
  },

  waterSources: {
    id: "waterSources",
    layerId,
    layerFieldName: "water_src",
    name: "Water Source",
    options: [],
    type: "multi-select-array",
    value: [],
    paint: {
      "fill-color": [
        "match",
        ["get", "water_src_fav"],
        "No Values Available",
        "#fff",
        "#fff",
      ],
    },
  },

  ownership: {
    id: "ownership",
    layerId,
    layerFieldName: "ownership",
    name: "Ownership",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "fill-color": [
        "match",
        ["get", "ownership"],
        "No Values Available",
        "#fff",
        "#fff",
      ],
    },
  },
  availability: {
    id: "availability",
    layerId,
    layerFieldName: "availability",
    name: "Availability",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "fill-color": [
        "match",
        ["get", "availability"],
        "No Values Available",
        "#fff",
        "#fff",
      ],
    },
  },
};
