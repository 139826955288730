import { Typography } from "@material-ui/core";
import FilterControl from "../filters/filterControl";
import Filter from "../filters/filter";
import WellStylesControl from "../controls/wellStylesControl";
import React from "react";
import styled from "styled-components/macro";

import useWadtLayerStyles from "../hooks/useWadtLayerStyles";

const FiltersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex: 1 1 0;
`;

const WadtFiltersBar = ({
  filterValues,
  handleFilterValues,
  handleSelectAll,
  handleSelectNone,
  updateLayerStyles,
  isMapLoaded,
}) => {
  const { activeStyle, handleActiveStyle, styleOptions } = useWadtLayerStyles({
    onLayerStyleChange: updateLayerStyles,
    isMapLoaded,
  });

  return (
    <>
      <FiltersSection>
        <FiltersContainer>
          <FilterControl
            width="100px"
            appliedCount={filterValues?.classes?.value?.length}
            label="Class"
          >
            <Filter
              label="Class"
              name="classes"
              onChange={handleFilterValues}
              onSelectAll={handleSelectAll}
              onSelectNone={handleSelectNone}
              options={filterValues?.classes?.options}
              type={filterValues?.classes?.type}
              value={filterValues?.classes?.value}
            />
          </FilterControl>
          <FilterControl
            width="110px"
            appliedCount={filterValues?.structures?.value?.length}
            label="Structure"
          >
            <Filter
              label="Structure"
              name="structures"
              onChange={handleFilterValues}
              onSelectAll={handleSelectAll}
              onSelectNone={handleSelectNone}
              options={filterValues?.structures?.options}
              type={filterValues?.structures?.type}
              value={filterValues?.structures?.value}
            />
          </FilterControl>
          <FilterControl
            width="145px"
            appliedCount={filterValues?.waterSources?.value?.length}
            label="Water Source"
          >
            <Filter
              label="Water Source"
              name="waterSources"
              onChange={handleFilterValues}
              onSelectAll={handleSelectAll}
              onSelectNone={handleSelectNone}
              options={filterValues?.waterSources?.options}
              type={filterValues?.waterSources?.type}
              value={filterValues?.waterSources?.value}
            />
          </FilterControl>
        </FiltersContainer>
      </FiltersSection>

      <FiltersSection>
        <FiltersContainer>
          <FilterControl
            width="220px"
            label={`Color wells by ${activeStyle.name}`}
          >
            <Typography variant="subtitle1" gutterBottom>
              Color wells by
            </Typography>
            <WellStylesControl
              label="Color wells by"
              name="wellStyles"
              onChange={handleActiveStyle}
              options={styleOptions}
              value={activeStyle.id}
            />
          </FilterControl>
        </FiltersContainer>
      </FiltersSection>
    </>
  );
};

export default WadtFiltersBar;
