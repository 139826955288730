import React from "react";
import { Box, Typography as MuiTypography } from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import { ChartTooltipContainer, ChartWrapper, getContrastYIQ } from "./utils";
import { ResponsiveLine } from "@nivo/line";

const Typography = styled(MuiTypography)(spacing);

const SeriesTooltipContainer = styled(Box)`
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  color: ${({ color }) => color};
  padding: 5px;
  margin: 5px 0;
`;

const CustomLineTooltip = ({ slice }) => {
  const { points } = slice;
  // Assuming all points share the same date
  const {
    data: { x: date },
  } = points[0];

  return (
    <ChartTooltipContainer backgroundcolor="black" color="white">
      <Typography variant="body2">{date}</Typography>
      {points.map((point, index) => {
        const {
          serieId: series,
          data: { y: value, units },
          serieColor: backgroundColor,
        } = point;
        const textColor = getContrastYIQ(backgroundColor);

        return (
          <SeriesTooltipContainer
            key={index}
            backgroundcolor={backgroundColor}
            color={textColor}
          >
            <Typography variant="body2">{series}</Typography>
            <Typography variant="body2">
              {value} {units}
            </Typography>
          </SeriesTooltipContainer>
        );
      })}
    </ChartTooltipContainer>
  );
};

const LineChart = ({ data }) => {
  return (
    <ChartWrapper height="400px">
      <ResponsiveLine
        data={data.series}
        margin={{ top: 5, right: 0, bottom: 75, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          reverse: false,
        }}
        gridXValues={data.ticks}
        axisTop={null}
        axisBottom={{ tickValues: data.ticks }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Degrees F",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        colors={({ color }) => color}
        enablePoints={false}
        useMesh={true}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateY: 50,
            itemsSpacing: 2,
            itemWidth: 125,
            itemHeight: 20,
            symbolSize: 20,
            itemTextColor: "black",
            symbolShape: "circle",
          },
        ]}
        sliceTooltip={CustomLineTooltip}
        enableSlices="x"
      />
    </ChartWrapper>
  );
};
export default LineChart;
