import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  ClickAwayListener,
  Divider,
  InputAdornment,
  List,
  ListItem,
  Paper,
  Popper,
  TextField,
  Typography,
  Grid,
  MenuItem,
  withWidth,
  isWidthDown,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Fuse from "fuse.js";
import useDebounce from "../../../../hooks/useDebounce";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";

const layersInfo = {
  "greeley-water-sources": {
    keys: [
      "wadt_source_name",
      "wadt_class_name",
      "water_src_fav",
      "diversion_name_fav",
      "ownership",
      "availability",
    ],
    searchPlaceholder: "Greeley Water Sources",
    searchFields: [
      { field: "wadt_source_name", label: "Name" },
      { field: "wadt_class_name", label: "Class" },
      { field: "water_src_fav", label: "Main Water Source" },
      { field: "diversion_name_fav", label: "Main Diversion" },
      { field: "ownership", label: "Ownership" },
      { field: "availability", label: "Availability" },
    ],
  },
};

const SearchResults = ({
  anchorEl,
  open,
  onClose,
  onSelect,
  searchResults,
  selectedLayer,
  width,
}) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      style={{ zIndex: 3 }}
      transition
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper
          style={{
            width: isWidthDown("xs", width) ? "200px" : "500px",
            height: isWidthDown("xs", width) ? 285 : 470,
            overflowY: "auto",
          }}
        >
          <List dense component="nav" aria-label="main mailbox folders">
            {searchResults?.slice(0, 49)?.map((result, i) => (
              <React.Fragment key={i}>
                <ListItem
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                  button
                  onClick={() => onSelect(result?.item)}
                >
                  <Typography variant="subtitle1">
                    {result?.item[
                      layersInfo[selectedLayer]?.searchFields[0]?.field
                    ] || "N/A"}
                  </Typography>
                  <Grid container>
                    {layersInfo[selectedLayer]?.searchFields[1]?.label && (
                      <Grid item xs={isWidthDown("xs", width) ? 12 : 4}>
                        <Typography variant="caption">
                          {layersInfo[selectedLayer]?.searchFields[1]?.label}
                        </Typography>
                        <Typography variant="body1">
                          {result?.item[
                            layersInfo[selectedLayer]?.searchFields[1]?.field
                          ] || "N/A"}
                        </Typography>
                      </Grid>
                    )}
                    {layersInfo[selectedLayer]?.searchFields[2]?.label && (
                      <Grid item xs={isWidthDown("xs", width) ? 12 : 4}>
                        <Typography variant="caption">
                          {layersInfo[selectedLayer]?.searchFields[2]?.label}
                        </Typography>
                        <Typography variant="body1">
                          {result?.item[
                            layersInfo[selectedLayer]?.searchFields[2]?.field
                          ] || "N/A"}
                        </Typography>
                      </Grid>
                    )}
                    {layersInfo[selectedLayer]?.searchFields[3]?.label && (
                      <Grid item xs={isWidthDown("xs", width) ? 12 : 4}>
                        <Typography variant="caption">
                          {layersInfo[selectedLayer]?.searchFields[3]?.label}
                        </Typography>
                        <Typography variant="body1">
                          {result?.item[
                            layersInfo[selectedLayer]?.searchFields[3]?.field
                          ] || "N/A"}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container>
                    {layersInfo[selectedLayer]?.searchFields[4]?.label && (
                      <Grid item xs={isWidthDown("xs", width) ? 12 : 4}>
                        <Typography variant="caption">
                          {layersInfo[selectedLayer]?.searchFields[4]?.label}
                        </Typography>
                        <Typography variant="body1">
                          {result?.item[
                            layersInfo[selectedLayer]?.searchFields[4]?.field
                          ] || "N/A"}
                        </Typography>
                      </Grid>
                    )}
                    {layersInfo[selectedLayer]?.searchFields[5]?.label && (
                      <Grid item xs={isWidthDown("xs", width) ? 12 : 4}>
                        <Typography variant="caption">
                          {layersInfo[selectedLayer]?.searchFields[5]?.label}
                        </Typography>
                        <Typography variant="body1">
                          {result?.item[
                            layersInfo[selectedLayer]?.searchFields[5]?.field
                          ] || "N/A"}
                        </Typography>
                      </Grid>
                    )}
                    {layersInfo[selectedLayer]?.searchFields[6]?.label && (
                      <Grid item xs={isWidthDown("xs", width) ? 12 : 4}>
                        <Typography variant="caption">
                          {layersInfo[selectedLayer]?.searchFields[6]?.label}
                        </Typography>
                        <Typography variant="body1">
                          {result?.item[
                            layersInfo[selectedLayer]?.searchFields[6]?.field
                          ] || "N/A"}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

const WadtSearch = ({ onSelect, sources, width }) => {
  const searchRef = useRef(null);

  const [options, setOptions] = useState(null);
  useEffect(() => {
    if (sources.length) {
      let searchData = {};
      sources.forEach((option) => {
        if (["greeley-water-sources"].includes(option.id)) {
          searchData[option.id] = option.data.features.map(
            (item) => item.properties
          );
        }
      });
      setOptions(searchData);
    }
  }, [sources]);

  const [selectedLayer, setSelectedLayer] = useState("greeley-water-sources");
  const [value, setValue] = useState("");
  const debouncedSearchValue = useDebounce(value, 200);
  const [searchResults, setSearchResults] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!searchResults?.length);
  }, [searchResults]);

  const fuzzySearcher = useMemo(() => {
    if (options) {
      return new Fuse(options[selectedLayer], {
        ignoreLocation: true,
        keys: layersInfo[selectedLayer].keys,
      });
    }
  }, [options, selectedLayer]);

  const handleClose = (event) => {
    if (searchRef.current && searchRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event) => {
    setValue(event?.target?.value);
  };

  useEffect(() => {
    const results = fuzzySearcher && fuzzySearcher.search(debouncedSearchValue);
    setSearchResults(results);
  }, [debouncedSearchValue, fuzzySearcher]);

  const [layersSelectOpen, setLayersSelectOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const layerOptions = [
    { value: "greeley-water-sources", label: "Greeley Water Sources" },
  ];
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setSelectedLayer(layerOptions[index].value);
    setLayersSelectOpen(false);
  };

  const handleToggle = () => {
    setLayersSelectOpen((prevOpen) => !prevOpen);
  };

  const handleCloseLayersSelect = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setLayersSelectOpen(false);
  };

  return (
    <>
      <>
        <ButtonGroup
          variant="contained"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
          style={{
            marginLeft: "4px",
          }}
        >
          <Button
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            disableElevation
            color="primary"
            variant="outlined"
            aria-controls={layersSelectOpen ? "split-button-menu" : undefined}
            aria-expanded={layersSelectOpen ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={layersSelectOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 1301 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseLayersSelect}>
                  <MenuList id="split-button-menu">
                    {layerOptions.map((option, index) => (
                      <MenuItem
                        key={option.value}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>

      <TextField
        autoComplete="off"
        id="outlined-basic"
        label={layersInfo[selectedLayer]?.searchPlaceholder || "Wells"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          style: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          },
        }}
        onChange={handleChange}
        onFocus={() => !!value && setOpen(true)}
        placeholder="Search Attributes"
        ref={searchRef}
        style={{
          width: "100%",
          minWidth: "245px",
          marginLeft: "-1px",
        }}
        type="search"
        value={value}
        variant="outlined"
        size="small"
      />
      <SearchResults
        anchorEl={searchRef?.current}
        onClose={handleClose}
        onSelect={onSelect}
        open={open}
        searchResults={searchResults}
        selectedLayer={selectedLayer}
        width={width}
      />
    </>
  );
};

export default withWidth()(WadtSearch);
