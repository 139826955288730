import { scaleSequential } from "d3-scale";
import { interpolateSpectral } from "d3-scale-chromatic";

export const transformDataForBarChart = (
  data,
  waterbodyNdx,
  color = "dynamic"
) => {
  const filteredData = data.filter(
    (item) => item.watershed_ndx === waterbodyNdx
  );

  const getColor =
    color === "dynamic"
      ? scaleSequential(interpolateSpectral).domain([0, filteredData.length])
      : () => color;

  const transformedData = filteredData.map((item, index) => {
    return {
      location_name: item.location_name, // used for the tooltip
      location_rivermile: item.location_rivermile, // determining the width of the bar and X axis
      last_reported_value: item.last_reported_value, // Y data value
      location_id: item.location_id, // represents the bar
      units: item.units, // used for the tooltip
      color: getColor(index), // Assign a unique color to each bar
    };
  });

  return transformedData;
};
