import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";

import { useBasicManualDataEntryInfo } from "./useBasicManualDataEntryInfo";
import { useSubmitData } from "../../../hooks/useSubmitData";
import Loader from "../../../components/Loader";
import { getButtonText, getButtonIcon } from "./utils";
function BasicManualDataEntryInfo({
  selectedManualDataEntry,
  setSelectedManualDataEntry,
  refetch,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    state,
    isDirty,
    handleResetState,
  } = useBasicManualDataEntryInfo(selectedManualDataEntry);

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedManualDataEntry,
    setSelectedManualDataEntry,
    state,
    "data-pp-accounting1031-values",
    "ndx",
    refetch
  );

  /**
   * Checks if the provided year is a valid four-digit year and not less than 2023
   *
   * @param {string | number} year - The year to validate. This can be a string or a number.
   * @returns {boolean} True if the year is valid (i.e., it is a four-digit year, can be parsed as a number,
   *                    and is not less than 2023). Returns false otherwise.
   */
  const isValidYear = (year) => {
    const parsedYear = parseInt(year, 10);
    return (
      !isNaN(parsedYear) &&
      parsedYear.toString().length === 4 &&
      parsedYear >= 2023
    );
  };

  /**
   * Checks if any specified fields in a global state object are truthy.
   * This function is intended to verify whether any of the listed fields in the state have a truthy value.
   *
   * @returns {boolean} Returns true if at least one of the specified fields in the state object has a truthy value.
   *                    A truthy value is any value that is not null, undefined, 0, false, "", or NaN. Returns false otherwise.
   */
  const hasAnyFieldsFilled = () => {
    // List of relevant state fields to check for being truthy
    const fields = [
      "actual_stor_bod_af",
      "meterread_out_bod_gal",
      "calc_stor_bod_af",
      "deliv_35th_cfs",
      "deliv_boyd_cfs",
      "gic_5_8ths_cfs",
      "newcache_cfs",
      "wssc_cfs",
      "leprino_cfs",
      "overlandtrl_cfs",
      "foreign_cfs",
      "otr_balance_af",
      "notes",
    ];
    return fields.some((field) => Boolean(state[field]));
  };

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ? (
        <Paper style={{ width: "100%", margin: "8px 0" }}>
          <Loader />
        </Paper>
      ) : (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box style={{ width: "100%" }}>
              <Typography variant="h3">General Information</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={6}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    required
                    error={!isValidYear(state.accounting_year)}
                    type="number"
                    variant="outlined"
                    label="Accounting Year"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      const year = e.target.value;
                      if (year === "") {
                        handleUpdateState("accounting_year", null);
                      } else {
                        if (isValidYear(year)) {
                          handleUpdateState(
                            "accounting_year",
                            parseInt(year, 10)
                          );
                        } else {
                          handleUpdateState("accounting_year", year);
                        }
                      }
                    }}
                    value={state?.accounting_year ?? ""}
                    InputProps={{
                      inputProps: {
                        min: 2023,
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                  {!isValidYear(state.accounting_year) && (
                    <FormHelperText error>
                      *Required field. Must be a valid year.
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="BOD Actual Storage Content"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("actual_stor_bod_af", null);
                      } else {
                        handleUpdateState(
                          "actual_stor_bod_af",
                          +e.target.value
                        );
                      }
                    }}
                    value={state?.actual_stor_bod_af ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="BOD Outflow Meter Reading"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("meterread_out_bod_gal", null);
                      } else {
                        handleUpdateState(
                          "meterread_out_bod_gal",
                          +e.target.value
                        );
                      }
                    }}
                    value={state?.meterread_out_bod_gal ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="BOD Calculated Storage Content"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("calc_stor_bod_af", null);
                      } else {
                        handleUpdateState("calc_stor_bod_af", +e.target.value);
                      }
                    }}
                    value={state?.calc_stor_bod_af ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="Owe the River Account Balance"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("otr_balance_af", null);
                      } else {
                        handleUpdateState("otr_balance_af", +e.target.value);
                      }
                    }}
                    value={state?.otr_balance_af ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="Measured Deliveries into Poudre Ponds from 35th Ave Ditch"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("deliv_35th_cfs", null);
                      } else {
                        handleUpdateState("deliv_35th_cfs", +e.target.value);
                      }
                    }}
                    value={state?.deliv_35th_cfs ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="Measured Deliveries into Poudre Ponds from Boyd Freeman"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("deliv_boyd_cfs", null);
                      } else {
                        handleUpdateState("deliv_boyd_cfs", +e.target.value);
                      }
                    }}
                    value={state?.deliv_boyd_cfs ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="GIC (5/8ths Deliveries)"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("gic_5_8ths_cfs", null);
                      } else {
                        handleUpdateState("gic_5_8ths_cfs", +e.target.value);
                      }
                    }}
                    value={state?.gic_5_8ths_cfs ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="Newcache"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("newcache_cfs", null);
                      } else {
                        handleUpdateState("newcache_cfs", +e.target.value);
                      }
                    }}
                    value={state?.newcache_cfs ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="WSSC"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("wssc_cfs", null);
                      } else {
                        handleUpdateState("wssc_cfs", +e.target.value);
                      }
                    }}
                    value={state?.wssc_cfs ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="Leprino"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("leprino_cfs", null);
                      } else {
                        handleUpdateState("leprino_cfs", +e.target.value);
                      }
                    }}
                    value={state?.leprino_cfs ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="Overland Trail"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("overlandtrl_cfs", null);
                      } else {
                        handleUpdateState("overlandtrl_cfs", +e.target.value);
                      }
                    }}
                    value={state?.overlandtrl_cfs ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="Other Foreign"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("foreign_cfs", null);
                      } else {
                        handleUpdateState("foreign_cfs", +e.target.value);
                      }
                    }}
                    value={state?.foreign_cfs ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="1999 Poudre Ponds Right"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("stor_deliv_1999_af", null);
                      } else {
                        handleUpdateState(
                          "stor_deliv_1999_af",
                          +e.target.value
                        );
                      }
                    }}
                    value={state?.stor_deliv_1999_af ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="2006 Poudre Ponds Right"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("stor_deliv_2006_af", null);
                      } else {
                        handleUpdateState(
                          "stor_deliv_2006_af",
                          +e.target.value
                        );
                      }
                    }}
                    value={state?.stor_deliv_2006_af ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                xl={6}
                style={{
                  position: "relative",
                }}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  required
                >
                  <TextField
                    multiline
                    rows={1}
                    variant="outlined"
                    label="Notes"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      handleUpdateState("notes", e.target.value);
                    }}
                    value={state?.notes ?? ""}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      <AccordionActions>
        <Button size="small" onClick={handleResetState}>
          Reset
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
          disabled={
            !isDirty ||
            isFormSubmitting ||
            !isValidYear(state.accounting_year) ||
            (isValidYear(state.accounting_year) && !hasAnyFieldsFilled())
          }
          startIcon={getButtonIcon(isFormSubmitting)}
          style={{ width: "100px" }}
        >
          {getButtonText(isFormSubmitting, selectedManualDataEntry === 0)}
        </Button>
      </AccordionActions>
    </>
  );
}

export default BasicManualDataEntryInfo;
