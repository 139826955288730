import React from "react";
import { Typography as MuiTypography } from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import { ResponsiveBar } from "@nivo/bar";

import { ChartTooltipContainer, ChartWrapper, getContrastYIQ } from "./utils";
import { useTooltip } from "@nivo/tooltip";

const Typography = styled(MuiTypography)(spacing);

const colorConfig = {
  "10 Year Average": { seriesColor: "#C9C9C9" },
  "Irrigation Water Requirement (IWR)": {
    seriesColor: "#00000000",
    tooltipColor: "#5CA740",
  },
  // The dynamic year precip color is uses the default
  default: { seriesColor: "#719BD0" },
};

const getColor = (id, forTooltip = false) => {
  const config = colorConfig[id] || colorConfig["default"];
  return forTooltip && config.tooltipColor
    ? config.tooltipColor
    : config.seriesColor;
};

const RenderCustomTooltip = ({
  backgroundColor,
  textColor,
  id,
  month,
  value,
  units,
}) => {
  return (
    <ChartTooltipContainer backgroundcolor={backgroundColor} color={textColor}>
      <Typography variant="body2">{id}</Typography>
      <Typography variant="body2">{month}</Typography>
      <Typography variant="body2">
        {value} {units}
      </Typography>
    </ChartTooltipContainer>
  );
};

const CustomBarTooltip = ({ id, value, color, data: { Month, units } }) => {
  // remove this conditional and the hidden overlay bar will also show the tooltip
  if (color === "#00000000") return null;

  const backgroundColor = getColor(id, true);
  const textColor = getContrastYIQ(backgroundColor);

  return (
    <RenderCustomTooltip
      backgroundColor={backgroundColor}
      textColor={textColor}
      id={id}
      month={Month}
      value={value}
      units={units}
    />
  );
};

const CustomLineTooltip = ({
  data: {
    id,
    value,
    data: { Month, units },
  },
}) => {
  const backgroundColor = getColor(id, true);
  const textColor = getContrastYIQ(backgroundColor);

  return (
    <RenderCustomTooltip
      backgroundColor={backgroundColor}
      textColor={textColor}
      id={id}
      month={Month}
      value={value}
      units={units}
    />
  );
};

const createDiamondPath = (x, y, size) => {
  const halfSize = size / 2;
  return `M ${x} ${y - halfSize} L ${x + halfSize} ${y} L ${x} ${
    y + halfSize
  } L ${x - halfSize} ${y} Z`;
};

const CustomLegendLayer = ({ bars, width }) => {
  const legendItems = [
    { id: "10 Year Average", color: "#C9C9C9" },
    { id: `${bars[0].data.data.year} Precip`, color: "#719BD0" },
    { id: "Irrigation Water Requirement (IWR)", color: "#5CA740" },
  ];

  const legendX = (width * 25) / 100;
  const legendY = -35;

  return (
    <g transform={`translate(${legendX}, ${legendY})`}>
      {legendItems.map((item, index) => (
        <g key={item.id} transform={`translate(${index * 125}, 0)`}>
          {item.id === "Irrigation Water Requirement (IWR)" ? (
            <path
              d={createDiamondPath(10, 10, 20)}
              fill={item.color}
              stroke="black"
              strokeWidth={1}
            />
          ) : (
            <rect width={20} height={20} fill={item.color} />
          )}

          <text
            x={25}
            y={15}
            style={{
              fill: "black",
              fontSize: "12px",
              fontFamily: "Arial, sans-serif",
            }}
          >
            {item.id}
          </text>
        </g>
      ))}
    </g>
  );
};

const IWRLineLayer = ({ bars, yScale }) => {
  const iwrData = bars
    .filter(
      ({ data: { id, value } }) =>
        id === "Irrigation Water Requirement (IWR)" && value !== null
    )
    .map((bar) => {
      return {
        ...bar,
        x: bar.x + bar.width / 2,
        y: yScale(bar.data.value),
      };
    });

  const linePath = iwrData
    .map((point, index) => {
      const command = index === 0 ? "M" : "L";
      return `${command}${point.x},${point.y}`;
    })
    .join(" ");

  const { showTooltipFromEvent, hideTooltip } = useTooltip();

  const handleMouseEnter = (e, data) => {
    showTooltipFromEvent(<CustomLineTooltip {...data} />, e);
  };

  return (
    <>
      <path d={linePath} fill="none" stroke="#5CA740" strokeWidth={0} />
      {iwrData.map((point) => {
        return (
          <path
            key={point.index}
            d={createDiamondPath(point.x, point.y, 15)}
            fill="#5CA740"
            stroke="black"
            strokeWidth={1}
            onMouseEnter={(e) => handleMouseEnter(e, point)}
            onMouseMove={(e) => handleMouseEnter(e, point)}
            onMouseLeave={hideTooltip}
          />
        );
      })}
    </>
  );
};

const BarChart = ({ data }) => {
  return (
    <ChartWrapper height="200px">
      <ResponsiveBar
        data={data}
        keys={[
          "10 Year Average",
          "Irrigation Water Requirement (IWR)",
          `${data[0].year} Precip`,
        ]}
        indexBy="Month"
        margin={{ top: 50, right: 0, bottom: 25, left: 60 }}
        padding={0.2}
        innerPadding={2}
        groupMode="grouped"
        colors={({ id }) => getColor(id)}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Precip. (in)",
          legendPosition: "middle",
          legendOffset: -40,
          tickValues: 5,
        }}
        gridYValues={5}
        enableLabel={false}
        legends={[
          {
            dataFrom: "keys",
            anchor: "top",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: -35,
            itemsSpacing: 2,
            itemWidth: 125,
            itemHeight: 20,
            itemTextColor: "black",
            symbolSize: 20,
          },
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        tooltip={CustomBarTooltip}
        layers={[
          "grid",
          "axes",
          "bars",
          "markers",
          CustomLegendLayer,
          IWRLineLayer,
        ]}
      />
    </ChartWrapper>
  );
};
export default BarChart;
