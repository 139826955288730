import React, { useCallback, useRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Link,
  Paper,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import MaterialTable from "material-table";
import CopyIcon from "@material-ui/icons/FileCopy";
import { Compare, ExpandMore, Visibility } from "@material-ui/icons";

import { NavLink } from "react-router-dom";
import { useApp } from "../../../../AppProvider";

import {
  copyToClipboard,
  dateFormatter,
  scrollToTarget,
} from "../../../../utils";
import BasicWaterSourceInfo from "./BasicWaterSourceInfo";
import { useWaterSources } from "./useWaterSources";
import WaterSourcesCompare from "./WaterSourcesCompare";
import PrintRefButton from "../../../../components/graphs/PrintRefButton";
import SaveRefButton from "../../../../components/graphs/SaveRefButton";
import { Alert } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

function WaterSourcesChangeLogsManagement() {
  const {
    selectedWaterSource,
    setSelectedWaterSource,
    data,
    isFetching,
    error,
    refetch,
    selectedRows,
    handleRowClick,
    selectedWaterSourcesCompare,
    setSelectedWaterSourcesCompare,
  } = useWaterSources();
  const { doToast, currentUser } = useApp();
  const readSaveRef = useRef(null);
  const compareSaveRef = useRef(null);

  const handleScrollToReadSave = useCallback(() => {
    setTimeout(() => scrollToTarget(readSaveRef), 1000);
  }, []);

  const handleScrollToCompareSave = useCallback(() => {
    setTimeout(() => scrollToTarget(compareSaveRef), 1000);
  }, []);

  const WATER_SOURCES_TABLE_COLUMNS = [
    {
      title: "Version",
      field: "version_timestamp",
      render: (rowData) =>
        dateFormatter(
          new Date(rowData.version_timestamp),
          "MM/DD/YYYY, h:mm A"
        ),
    },
    {
      title: "WADT Source Name",
      field: "wadt_source_name",
    },
    {
      title: "WADT Class",
      field: "s_class",
    },
    {
      title: "Water Source",
      field: "water_source",
    },
    {
      title: "Ditch/System Source",
      field: "diversion_name_fav",
    },
  ];

  if (error) return "An error has occurred: " + error.message;
  return (
    <React.Fragment>
      <Helmet title="WADT Water Source Change Log" />
      <Typography variant="h3" gutterBottom display="inline">
        WADT Water Source Change Log
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={currentUser?.home || "/"}>
          Dashboard
        </Link>
        <Typography>WADT Water Source Change Log</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <div>
        <Box px={4} my={3} display="flex" alignItems="center">
          <InfoIcon
            style={{ fontSize: 48, marginRight: 16 }}
            color="secondary"
          />{" "}
          {/* Adjust icon size */}
          <div>
            <Alert
              severity="info"
              icon={<Visibility />}
              style={{ display: "flex", alignItems: "center" }}
            >
              <strong>Viewing Source Change Log</strong> To view the detailed
              information entered for a WADT Water Source Change Log, click the
              "eyeball" action-icon to the left of the row.
            </Alert>
            <Alert
              severity="info"
              icon={<Compare />}
              style={{ display: "flex", alignItems: "center" }}
            >
              <strong>Comparing Multiple Source Change Logs</strong> Clicking
              anywhere on a row will select a Source Change Log for comparison.
              Select up to 4 sources and then click the "compare" action icon
              which is above the top right corner of the table.
            </Alert>
          </div>
        </Box>
      </div>

      <Box>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box style={{ width: "100%" }}>
              <Typography variant="h3">
                Water Source Change Log Selection
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <TableWrapper>
              <MaterialTable
                id="WADT Water Source Change Log"
                title={`WADT Water Source Change Log ${dateFormatter(
                  new Date(),
                  "MM/DD/YYYY, h:mm A"
                )}`}
                loading={isFetching || !data}
                columns={WATER_SOURCES_TABLE_COLUMNS}
                data={data}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Water Source Change Log Selection",
                  },
                }}
                // editable={{
                //   onRowDelete: handleDelete,
                // }}
                actions={[
                  {
                    icon: CopyIcon,
                    tooltip: "Copy Data",
                    isFreeAction: true,
                    onClick: () => {
                      try {
                        copyToClipboard(data, WATER_SOURCES_TABLE_COLUMNS, () =>
                          doToast(
                            "success",
                            "Data was copied to your clipboard."
                          )
                        );
                      } catch (error) {
                        const message =
                          error?.message ?? "Something went wrong";
                        doToast("error", message);
                      }
                    },
                  },
                  () => ({
                    icon: "visibility",
                    tooltip: "View",
                    onClick: (event, rowData) => {
                      setSelectedWaterSource(rowData?.ndx);
                      setSelectedWaterSourcesCompare([]);
                      handleScrollToReadSave();
                    },
                  }),
                  {
                    icon: "compare",
                    tooltip: "Compare",
                    isFreeAction: true,
                    disabled: selectedRows.length < 2,
                    onClick: () => {
                      setSelectedWaterSourcesCompare(selectedRows);
                      setSelectedWaterSource(null);
                      handleScrollToCompareSave();
                    },
                  },
                  // {
                  //   icon: "add_box",
                  //   tooltip: "Add",
                  //   isFreeAction: true,
                  //   onClick: () => {
                  //     setSelectedWaterSource(0);
                  //   },
                  // },
                ]}
                options={{
                  filtering: true,
                  emptyRowsWhenPaging: false,
                  showTitle: false,
                  columnsButton: true,
                  exportButton: true,
                  exportAllData: true,
                  addRowPosition: "first",
                  pageSize: 50,
                  pageSizeOptions: [10, 30, 50, 100, 200],
                  padding: "dense",
                  searchFieldAlignment: "left",
                  minBodyHeight: "250px",
                  maxBodyHeight:
                    "calc(100vh - 64px - 48px - 106px - 48px - 64px - 204px)",
                  rowStyle: (rowData) => {
                    const index = selectedRows.indexOf(rowData.ndx);
                    if (index === 0) {
                      return {
                        border: "4px solid red", // Red for the first selected row
                      };
                    } else if (index === 1) {
                      return {
                        border: "4px solid blue", // Blue for the second selected row
                      };
                    } else if (index === 2) {
                      return {
                        border: "4px solid orange", // Blue for the second selected row
                      };
                    } else if (index === 3) {
                      return {
                        border: "4px solid green", // Blue for the second selected row
                      };
                    } else {
                      return {
                        border: "inherit", // Inherit for other rows
                      };
                    }
                  },
                }}
                onRowClick={handleRowClick}
              />
            </TableWrapper>
          </AccordionDetails>
        </Accordion>

        <Box
          style={{
            display: selectedWaterSourcesCompare.length > 0 ? "block" : "none",
          }}
        >
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box pv={4} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4">
                  Comparing Water Source Change Logs
                </Typography>
                <PrintRefButton
                  data-html2canvas-ignore
                  ref={compareSaveRef}
                  title="Water Sources Compare"
                />
                <SaveRefButton
                  data-html2canvas-ignore
                  ref={compareSaveRef}
                  title="Water Sources Compare"
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedWaterSourcesCompare([])}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
          <div ref={compareSaveRef}>
            <WaterSourcesCompare
              selectedWaterSourcesCompare={selectedWaterSourcesCompare}
            />
          </div>
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box pv={4} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4">
                  Comparing Water Source Change Logs
                </Typography>
                <PrintRefButton
                  data-html2canvas-ignore
                  ref={compareSaveRef}
                  title="Water Sources Compare"
                />
                <SaveRefButton
                  data-html2canvas-ignore
                  ref={compareSaveRef}
                  title="Water Sources Compare"
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedWaterSourcesCompare([])}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
        </Box>

        <Box
          style={{
            display:
              selectedWaterSource || selectedWaterSource === 0
                ? "block"
                : "none",
          }}
        >
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box pv={4} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4">
                  {selectedWaterSource === 0
                    ? "Creating New Water Source"
                    : "Viewing Water Source Change Log"}
                </Typography>
                <PrintRefButton
                  data-html2canvas-ignore
                  ref={readSaveRef}
                  title="Water Source Change Log Compare"
                />
                <SaveRefButton
                  data-html2canvas-ignore
                  ref={readSaveRef}
                  title="Water Source Change Log Compare"
                />
              </Box>

              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedWaterSource(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
          <div ref={readSaveRef}>
            <BasicWaterSourceInfo
              selectedWaterSource={selectedWaterSource}
              setSelectedWaterSource={setSelectedWaterSource}
              refetchWaterSources={refetch}
              selectedWaterSourcesCompare={selectedWaterSourcesCompare}
            />
          </div>
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box pv={4} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4">
                  {selectedWaterSource === 0
                    ? "Creating New Water Source"
                    : "Viewing Water Source Change Log"}
                </Typography>
                <PrintRefButton
                  data-html2canvas-ignore
                  ref={readSaveRef}
                  title="Water Source Change Log Compare"
                />
                <SaveRefButton
                  data-html2canvas-ignore
                  ref={readSaveRef}
                  title="Water Sources Compare"
                />
              </Box>

              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedWaterSource(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default WaterSourcesChangeLogsManagement;
