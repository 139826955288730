import React, { useCallback, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { DatePicker, Select } from "@lrewater/lre-react";
import {
  dateFormatter,
  extractDate,
  getLookupForDataSortedByField,
  standardizeColor,
} from "../../../utils";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Button as MuiButton,
  Box,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography as MuiTypography,
  Tooltip,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Panel from "../../../components/panels/Panel";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import { spacing } from "@material-ui/system";
import Table from "../../../components/Table";
import { Helmet } from "react-helmet-async";
import { add } from "date-fns";
import TimeseriesLineChart from "../../../components/graphs/TimeseriesLineChart";
import { NavLink } from "react-router-dom";
import { useApp } from "../../../AppProvider";
import useFetchData from "../../../hooks/useFetchData";

const Button = styled(MuiButton)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100% - 35px);
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
`;

const TimeseriesContainer = styled.div`
  height: 600px;
  width: 100%;
`;

const SubmitGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
`;

const createDataset = (location, yAxisID) => ({
  data: location.map((item) => ({
    x: item.collect_timestamp,
    y: item.result_value,
  })),
  yAxisID: yAxisID,
  units: location[0].unit_display_label,
  pointStyle: "circle",
  fill: false,
  borderWidth: yAxisID === "yR" ? 3 : 2,
  spanGaps: true,
  pointRadius: 2,
  pointHoverRadius: 4,
  label: `${location[0].location_display_label}${
    yAxisID === "yR" ? " (R)" : ""
  }`,
  borderColor: location[0].legend_color,
  backgroundColor: standardizeColor(location[0].legend_color),
  tension: 0.5,
  ...(yAxisID === "yR" ? { borderDash: [10, 5] } : {}),
});

const QuickSetButton = ({ onClick, disabled = false, children }) => (
  <Tooltip title="Quick set dates">
    <span>
      <Button
        disabled={disabled}
        ml={3}
        onClick={onClick}
        color="primary"
        variant="contained"
        size="small"
      >
        {children}
      </Button>
    </span>
  </Tooltip>
);

const SystemGraphs = ({
  defaultParameter,
  pageTitle,
  endpoints,
  parametersEndpoint,
  rightAxis = false,
}) => {
  const saveRef = useRef(null);
  const { currentUser } = useApp();

  const [filterValues, setFilterValues] = useState({
    endpoint: endpoints[0].value,
    parameter: defaultParameter,
    startDate: extractDate(add(new Date(), { days: -3 })),
    endDate: extractDate(new Date()),
  });

  const [WateryearDates] = useFetchData("wateryear-start-end-dates", [], false);

  const {
    last_wateryear_start,
    last_wateryear_end,
    current_wateryear_start,
    current_wateryear_end,
  } = WateryearDates[0] || {};

  const setQuickFilter = useCallback(({ start, end, days }) => {
    const startDate =
      days !== undefined ? add(new Date(), { days: -days }) : new Date(start);
    const endDate = end ? new Date(end) : new Date();

    setFilterValues((prev) => ({
      ...prev,
      startDate: extractDate(startDate),
      endDate: extractDate(endDate),
    }));
  }, []);

  const { data: Parameters } = useQuery(
    [parametersEndpoint],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${parametersEndpoint}`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleFilter = useCallback((event) => {
    const { name, value } = event.target;
    setFilterValues((prev) => ({ ...prev, [name]: value }));
  }, []);

  const { data, error, isFetching, refetch } = useQuery(
    [pageTitle],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${filterValues.endpoint}/${
            filterValues.parameter
          }/${filterValues.startDate}/${extractDate(
            add(new Date(filterValues.endDate), { days: 2 })
          )}`
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  const {
    data: dataRightAxis,
    error: errorRightAxis,
    isFetching: isFetchingRightAxis,
    refetch: refetchRightAxis,
  } = useQuery(
    [pageTitle],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${
            filterValues.endpoint.slice(0, -1) + "2"
          }/${filterValues.startDate}/${extractDate(
            add(new Date(filterValues.endDate), { days: 2 })
          )}`
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: !!rightAxis,
      cacheTime: 0,
    }
  );

  const getFilteredData = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.flat().filter((record) => record.result_value !== null);
  };

  const tableColumns = useMemo(
    () => [
      {
        title: "Location",
        field: "location_display_label",
        lookup: getLookupForDataSortedByField(
          getFilteredData(data),
          "location_display_label"
        ),
      },
      {
        title: "Parameter",
        field: "parameter_display_label",
        filtering: false,
      },
      { title: "Units", field: "unit_display_label", filtering: false },
      {
        title: "Timestamp",
        field: "collect_timestamp",
        render: (rowData) => {
          return dateFormatter(rowData.collect_timestamp, "MM/DD/YYYY, h:mm A");
        },
        filtering: false,
      },
      { title: "Value", field: "result_value", filtering: false },
      { title: "Source", field: "datasrc_name", filtering: false },
    ],
    [data, dataRightAxis] //eslint-disable-line
  );

  const graphData = useMemo(() => {
    if (!data) return { datasets: [] };

    const leftAxisDatasets = data.map((location) =>
      createDataset(location, "yL")
    );
    const rightAxisDatasets =
      rightAxis && dataRightAxis?.length
        ? dataRightAxis.map((location) => createDataset(location, "yR"))
        : [];

    return { datasets: [...leftAxisDatasets, ...rightAxisDatasets] };
  }, [data, dataRightAxis, rightAxis]);

  const firstItem = data?.[0]?.[0] ?? {};
  const { parameter_display_label = "N/A", unit_display_label = "N/A" } =
    firstItem;

  return (
    <>
      <Helmet title={pageTitle} />
      <Typography variant="h3" gutterBottom display="inline">
        {pageTitle}
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={currentUser?.home || "/"}>
          Dashboard
        </Link>
        <Typography>{pageTitle}</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      <>
        {data && dataRightAxis && Parameters && (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="time-series"
                  id="time-series"
                >
                  <Typography variant="h4" ml={2}>
                    {`${pageTitle} / ${parameter_display_label}, ${unit_display_label}`}
                  </Typography>
                </AccordionSummary>
                <Panel>
                  <AccordionDetails>
                    <TimeseriesContainer>
                      <Box
                        pb={1}
                        display="flex"
                        alignItems="center"
                        style={{ width: "100%" }}
                      >
                        <span
                          style={{
                            marginLeft: "auto",
                          }}
                        >
                          <SaveGraphButton
                            ref={saveRef}
                            title={`${pageTitle} / ${parameter_display_label}, ${unit_display_label}`}
                            disabled={isFetching || !data}
                          />
                        </span>
                      </Box>

                      <TableWrapper>
                        <TimeseriesLineChart
                          data={graphData}
                          error={error || errorRightAxis}
                          isLoading={isFetching || isFetchingRightAxis}
                          reverseLegend={false}
                          filterValues={filterValues}
                          yLLabel={`${parameter_display_label} (${unit_display_label})`}
                          xLabelUnit="day"
                          yRLabel={
                            rightAxis
                              ? `${parameter_display_label} (${unit_display_label})`
                              : null
                          }
                          ref={saveRef}
                          title={`${pageTitle} / ${parameter_display_label}, ${unit_display_label}`}
                          tooltipFormat={
                            filterValues.endpoint === endpoints[0].value
                              ? "MM-DD-YYYY, h:mm A"
                              : "MM-DD-YYYY"
                          }
                        />
                      </TableWrapper>
                    </TimeseriesContainer>
                  </AccordionDetails>
                </Panel>
              </Accordion>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={6}>
          {endpoints && Parameters && WateryearDates && (
            <>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="time-series"
                    id="time-series"
                  >
                    <Typography variant="h4" ml={2}>
                      Options
                    </Typography>
                  </AccordionSummary>
                  <Panel>
                    <AccordionDetails>
                      <Grid container pb={6} mt={2}>
                        <Grid item xs={12}>
                          <Select
                            name="parameter"
                            label="Parameter"
                            variant="outlined"
                            valueField="parameter_ndx"
                            displayField="parameter_name"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                            data={Parameters}
                            value={filterValues.parameter}
                            onChange={handleFilter}
                            width={220}
                          />
                          <Select
                            name="endpoint"
                            label="Graph Options"
                            variant="outlined"
                            valueField="value"
                            displayField="label"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                            data={endpoints}
                            value={filterValues.endpoint}
                            onChange={handleFilter}
                            width="calc(100% - 162px - 390px)"
                          />
                          <DatePicker
                            name="startDate"
                            label="Start Date"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            value={filterValues.startDate}
                            onChange={handleFilter}
                            width={150}
                          />
                          <DatePicker
                            name="endDate"
                            label="End Date"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            value={filterValues.endDate}
                            onChange={handleFilter}
                            width={150}
                          />
                        </Grid>

                        <SubmitGrid item container>
                          <Grid>
                            <Button
                              onClick={() => {
                                refetch();
                                refetchRightAxis();
                              }}
                              type="submit"
                              color="secondary"
                              variant="contained"
                              size="large"
                              disabled={
                                !filterValues.startDate || !filterValues.endDate
                              }
                            >
                              Submit
                            </Button>
                          </Grid>
                          <Grid item style={{ marginLeft: "auto" }}>
                            <QuickSetButton
                              disabled={
                                filterValues.endpoint !== "display-scada-daily"
                              }
                              onClick={() =>
                                setQuickFilter({
                                  start: last_wateryear_start,
                                  end: current_wateryear_end,
                                })
                              }
                            >
                              Last and Current Water Years
                            </QuickSetButton>
                            <QuickSetButton
                              disabled={
                                filterValues.endpoint !== "display-scada-daily"
                              }
                              onClick={() =>
                                setQuickFilter({
                                  start: last_wateryear_start,
                                  end: last_wateryear_end,
                                })
                              }
                            >
                              Last Water Year
                            </QuickSetButton>
                            <QuickSetButton
                              disabled={
                                filterValues.endpoint !== "display-scada-daily"
                              }
                              onClick={() =>
                                setQuickFilter({
                                  start: current_wateryear_start,
                                  end: current_wateryear_end,
                                })
                              }
                            >
                              Current Water Year
                            </QuickSetButton>
                            <QuickSetButton
                              onClick={() => setQuickFilter({ days: 45 })}
                            >
                              Last 45 Days
                            </QuickSetButton>
                            <QuickSetButton
                              onClick={() => setQuickFilter({ days: 7 })}
                            >
                              Last 7 Days
                            </QuickSetButton>
                            <QuickSetButton
                              onClick={() => setQuickFilter({ days: 3 })}
                            >
                              Last 3 Days
                            </QuickSetButton>
                          </Grid>
                        </SubmitGrid>
                      </Grid>
                    </AccordionDetails>
                  </Panel>
                </Accordion>
              </Grid>
            </>
          )}
        </Grid>

        {data && !isFetching && (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="table-content"
                  id="table-header"
                >
                  <Typography variant="h4" ml={2}>
                    Table
                  </Typography>
                </AccordionSummary>
                <Panel>
                  <AccordionDetails>
                    <TableWrapper>
                      <Table
                        // isLoading={isLoading}
                        label={`${pageTitle} / ${parameter_display_label}, ${unit_display_label}`}
                        columns={tableColumns}
                        data={getFilteredData(data)}
                        height="590px"
                        options={{ filtering: true }}
                      />
                    </TableWrapper>
                  </AccordionDetails>
                </Panel>
              </Accordion>
            </Grid>
          </Grid>
        )}
      </>
    </>
  );
};

export default SystemGraphs;
