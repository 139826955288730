import React from "react";
import { useUsDsBarChart } from "./useUsDsBarChart";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Loader from "../../../../components/Loader";
import { ResponsiveBar } from "@nivo/bar";
import styled from "styled-components/macro";

const ChartContainer = styled.div`
  //Nivo chart needs explicit height, removes parent padding and input height
  height: ${({ height }) => height - 37}px;
`;

const ChartTooltipContainer = styled(Box)`
  background-color: black;
  padding: 10px;
  border-radius: 5px;
  color: white;
  min-width: 225px;
  bottom: 0;
  position: absolute;
`;

const CustomTooltip = ({ data, index, length }) => {
  const {
    location_name,
    location_id,
    location_rivermile,
    last_reported_value,
    units,
  } = data;

  const isFirstHalf = index + 1 < length / 2;

  return (
    <ChartTooltipContainer style={isFirstHalf ? { left: 0 } : { right: 0 }}>
      <Typography variant="body2">{location_name}</Typography>
      <Typography variant="body2">
        {location_id} @ mile {location_rivermile}
      </Typography>
      <Typography variant="body2">
        {last_reported_value} {units}
      </Typography>
    </ChartTooltipContainer>
  );
};

const formatTickValue = (value, data) => {
  // Find the data object that corresponds to the current tick value
  const dataItem = data.find((item) => item.location_id === value);
  // Use other properties of dataItem for formatting
  return dataItem ? dataItem.location_rivermile : value;
};

const UsDsBarChart = ({ height }) => {
  const { graphData, lookups, filters, handleFilterChange, error, isLoading } =
    useUsDsBarChart();

  if (error) {
    console.error("Error fetching data:", error);
    return { error };
  }
  return isLoading ? (
    <Loader />
  ) : (
    <>
      <FormControl variant="outlined" fullWidth size="small">
        {!lookups?.waterbodies.length ? (
          <Loader />
        ) : (
          <>
            <InputLabel id="precinct">Waterbody</InputLabel>
            <Select
              labelId="waterbody"
              id="waterbody"
              label="Waterbody"
              name="waterbody"
              value={filters.waterbody ?? ""}
              onChange={(e) => {
                handleFilterChange("waterbody", e.target.value);
              }}
            >
              {lookups.waterbodies.map((option) => (
                <MenuItem
                  key={option.watershed_ndx}
                  value={option.watershed_ndx}
                >
                  {option.waterbody}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </FormControl>
      <ChartContainer height={height}>
        <ResponsiveBar
          data={graphData}
          keys={["last_reported_value"]}
          indexBy="location_id"
          margin={{ top: 15, right: 0, bottom: 55, left: 50 }}
          padding={0.2}
          colors={(bar) => bar.data.color}
          borderColor={{ from: "color", modifiers: [["darker", 2]] }}
          borderWidth={2}
          borderRadius={5}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            legend: "Location Rivermile",
            legendPosition: "middle",
            legendOffset: 50,
            format: (value) => formatTickValue(value, graphData),
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Flow, CFS",
            legendPosition: "middle",
            legendOffset: -45,
          }}
          enableLabel={false}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          tooltip={(props) => (
            <CustomTooltip {...props} length={graphData?.length ?? 0} />
          )}
        />
      </ChartContainer>
    </>
  );
};

export default UsDsBarChart;
