import React, { useRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Link,
  Paper,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import MaterialTable from "material-table";
import CopyIcon from "@material-ui/icons/FileCopy";
import { ExpandMore } from "@material-ui/icons";

import { NavLink } from "react-router-dom";

import BasicWtpDataAdjustmentInfo from "./BasicWtpDataAdjustmentInfo";
import { useWtpDataAdjustments } from "./useWtpDataAdjustments";
import { useApp } from "../../../AppProvider";
import { copyToClipboard, dateFormatter } from "../../../utils";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

function WtpDataAdjustments() {
  const {
    selectedDataAdjustment,
    setSelectedDataAdjustment,
    data,
    isFetching,
    error,
    refetch,
    COLUMNS,
  } = useWtpDataAdjustments();
  const { doToast, currentUser } = useApp();
  const { getAccessTokenSilently } = useAuth0();
  const readSaveRef = useRef(null);

  const handleDelete = (oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.delete(
            `${process.env.REACT_APP_ENDPOINT}/api/data-overrides-wtp/${oldData.ndx}`,
            { headers }
          );
          doToast("success", "This entry was deleted from the database");
          if (selectedDataAdjustment === oldData.ndx) {
            setSelectedDataAdjustment(null);
          }

          await refetch();
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  if (error) return "An error has occurred: " + error.message;
  return (
    <React.Fragment>
      <Helmet title="WTP Data Adjustments" />
      <Typography variant="h3" gutterBottom display="inline">
        WTP Data Adjustments
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={currentUser?.home || "/"}>
          Dashboard
        </Link>
        <Typography>WTP Data Adjustments</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Box>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box style={{ width: "100%" }}>
              <Typography variant="h3">Adjustments</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <TableWrapper>
              <MaterialTable
                id="WTP Data Adjustments"
                title={`WTP Data Adjustments ${dateFormatter(
                  new Date(),
                  "MM/DD/YYYY, h:mm A"
                )}`}
                loading={isFetching || !data}
                columns={COLUMNS}
                data={data}
                localization={{
                  toolbar: { searchPlaceholder: "Adjustments" },
                }}
                editable={{
                  onRowDelete: handleDelete,
                }}
                actions={[
                  {
                    icon: CopyIcon,
                    tooltip: "Copy Data",
                    isFreeAction: true,
                    onClick: () => {
                      try {
                        copyToClipboard(data, COLUMNS, () =>
                          doToast(
                            "success",
                            "Data was copied to your clipboard."
                          )
                        );
                      } catch (error) {
                        const message =
                          error?.message ?? "Something went wrong";
                        doToast("error", message);
                      }
                    },
                  },
                  () => ({
                    icon: "edit",
                    tooltip: "Edit",
                    onClick: (event, rowData) => {
                      setSelectedDataAdjustment(rowData?.ndx);
                    },
                  }),
                  {
                    icon: "add_box",
                    tooltip: "Add",
                    isFreeAction: true,
                    onClick: () => {
                      setSelectedDataAdjustment(0);
                    },
                  },
                ]}
                options={{
                  filtering: true,
                  emptyRowsWhenPaging: false,
                  showTitle: false,
                  columnsButton: true,
                  exportButton: true,
                  exportAllData: true,
                  addRowPosition: "first",
                  pageSize: 50,
                  pageSizeOptions: [10, 30, 50, 100, 200],
                  padding: "dense",
                  searchFieldAlignment: "left",
                  minBodyHeight: "250px",
                  maxBodyHeight:
                    "calc(100vh - 64px - 48px - 106px - 48px - 64px - 204px)",
                }}
              />
            </TableWrapper>
          </AccordionDetails>
        </Accordion>

        <Box
          style={{
            display:
              selectedDataAdjustment || selectedDataAdjustment === 0
                ? "block"
                : "none",
          }}
        >
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box pv={4} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4">
                  {selectedDataAdjustment === 0
                    ? "Creating New WTP Data Adjustment"
                    : "Editing Existing WTP Data Adjustment"}
                </Typography>
              </Box>

              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedDataAdjustment(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
          <div ref={readSaveRef}>
            <BasicWtpDataAdjustmentInfo
              selectedDataAdjustment={selectedDataAdjustment}
              setSelectedDataAdjustment={setSelectedDataAdjustment}
              refetch={refetch}
            />
          </div>
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box pv={4} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4">
                  {selectedDataAdjustment === 0
                    ? "Creating New WTP Data Adjustment"
                    : "Editing Existing WTP Data Adjustment"}
                </Typography>
              </Box>

              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedDataAdjustment(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default WtpDataAdjustments;
