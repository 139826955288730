import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useState } from "react";

export const useWaterSources = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedWaterSource, setSelectedWaterSource] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedWaterSourcesCompare, setSelectedWaterSourcesCompare] =
    useState([]);
  const handleRowClick = (event, rowData) => {
    if (selectedRows.includes(rowData.wadt_source_ndx)) {
      setSelectedRows(
        selectedRows.filter((row) => row !== rowData.wadt_source_ndx)
      );
    } else {
      if (selectedRows.length < 4) {
        setSelectedRows([...selectedRows, rowData.wadt_source_ndx]);
      }
    }
  };

  const { data, isFetching, error, refetch } = useQuery(
    ["ui-report-sources"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-report-sources`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    selectedWaterSource,
    setSelectedWaterSource,
    data,
    isFetching,
    error,
    refetch,
    selectedRows,
    setSelectedRows,
    handleRowClick,
    selectedWaterSourcesCompare,
    setSelectedWaterSourcesCompare,
  };
};
