import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";

import Loader from "../../../../components/Loader";

import { useWaterSourcesCompare } from "./useWaterSourcesCompare";
import { titleize } from "inflected";

function WaterSourcesCompare({ selectedWaterSourcesCompare }) {
  const { data, isLoading, error, categories, selectedColors } =
    useWaterSourcesCompare(selectedWaterSourcesCompare);

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading && (
        <Paper style={{ width: "100%", margin: "8px 0" }}>
          <Loader />
        </Paper>
      )}

      {data?.length > 0 && (
        <>
          {categories.map((category, catIndex) => {
            return (
              <Accordion
                defaultExpanded
                style={{
                  display: isLoading ? "none" : "block",
                  pageBreakBefore: "always",
                }}
                key={catIndex}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Box style={{ width: "100%" }}>
                    <Typography variant="h3">{category.title}</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {Object.keys(data[0])
                      .filter(
                        (item) =>
                          category.properties.includes(item) ||
                          ["wadt_source_name", "version_timestamp"].includes(
                            item
                          )
                      )
                      .map((key, i) => {
                        return (
                          <React.Fragment key={key}>
                            {/* Header */}
                            <Grid item xs={12}>
                              <Typography
                                variant="h5"
                                style={{
                                  fontWeight: [0, 1].includes(i)
                                    ? "900"
                                    : "normal",
                                }}
                              >
                                {titleize(key)}
                              </Typography>
                            </Grid>
                            {/* Data */}
                            <Grid item xs={12} style={{ display: "flex" }}>
                              {data.map((item, index) => {
                                return (
                                  <div
                                    style={{
                                      border: `3px solid ${selectedColors[index]}`,
                                      marginRight: "3px",
                                      padding: "10px",
                                      flexGrow: 1,
                                      width: "50%",
                                    }}
                                    key={index}
                                  >
                                    <Typography
                                      style={{
                                        fontSize: "16px",
                                        color: "black",
                                        fontWeight: [
                                          "wadt_source_name",
                                          "version_timestamp",
                                        ].includes(key)
                                          ? "600"
                                          : "normal",
                                      }}
                                    >
                                      {data[index][key]}
                                    </Typography>
                                  </div>
                                );
                              })}
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </>
      )}
    </>
  );
}

export default WaterSourcesCompare;
