import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";

import Loader from "../../../../components/Loader";
import { useBasicWaterSourceInfo } from "./useBasicWaterSourceInfo";

function BasicWaterSourceInfo({
  selectedWaterSource,
  // setSelectedWaterSource,
  isEditMode = false,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    state,
    // refetch,
    // isDirty,
  } = useBasicWaterSourceInfo(selectedWaterSource);

  // const { handleSubmit, isFormSubmitting } = useSubmitData(
  //   selectedWaterSource,
  //   setSelectedWaterSource,
  //   state,
  //   "ui-report-sources",
  //   "wadt_source_ndx",
  //   refetch
  // );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.wadt_source_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.wadt_source_ndx)
              ? "none"
              : "block",
          pageBreakBefore: "always",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">General Information</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                size="large"
                variant="contained"
                color="primary"
                target="_blank"
                rel="noreferrer"
                href={`/water-acquisition-tool/acquisitions-and-opportunities/new/${selectedWaterSource}`}
              >
                Log New Opportunity for this Source
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={8}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="WADT Source Name"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("wadt_source_name", e.target.value)
                }
                value={state.wadt_source_name ?? "N/A"}
                inputProps={{
                  style: {
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={2}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Abbreviation"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("wadt_source_abbrev", e.target.value)
                }
                value={state.wadt_source_abbrev ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={2}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Class"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("s_class", e.target.value)}
                value={state.s_class ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            {state?.wadt_class_ndx === 999 && (
              <Grid
                item
                xs={12}
                style={{
                  position: "relative",
                }}
              >
                <TextField
                  disabled
                  multiline
                  rows={1}
                  rowsMax={100}
                  variant="outlined"
                  label="Reason for No Classification"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleUpdateState("reason_for_exclusion", e.target.value)
                  }
                  value={state.reason_for_exclusion ?? "N/A"}
                  inputProps={{
                    style: {
                      // fontWeight: "bold",
                      fontSize: "16px",
                      color: "black",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "black",
                    },
                  }}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={6}
              xl={8}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Location (Source Water)"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("water_source", e.target.value)
                }
                value={state.water_source ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Water DIstrict"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("district", e.target.value)}
                value={state.district ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={8}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Water Right Structure(s)"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("diversion_name_fav", e.target.value)
                }
                value={state.diversion_name_fav ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button size="large" variant="contained" color="primary">
                View Water Rights Tabulation
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Potable or Non-potable Supply"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("potability", e.target.value)
                }
                value={state.potability ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Type(s) of Water Rights"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("water_right_type", e.target.value)
                }
                value={state.water_right_type ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.wadt_source_ndx)
              ? "none"
              : "block",
          pageBreakBefore: "always",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Yield</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              xl={2}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Delivery Yield, AF per Share"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("yieldhdgt_afpsh", e.target.value)
                }
                value={state.yieldhdgt_afpsh ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={2}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Consumptive Use Yield, AF per Share"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("yieldcu_afpsh", e.target.value)
                }
                value={state.yieldcu_afpsh ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={8}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Yield Notes"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("yield_notes", e.target.value)
                }
                value={state.yield_notes ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.wadt_source_ndx)
              ? "none"
              : "block",
          pageBreakBefore: "always",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Cost</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Purchase Price per Share"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("cost_per_share", e.target.value)
                }
                value={state.cost_per_share ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Purchase Price per AF"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("cost_per_af", e.target.value)
                }
                value={state.cost_per_af ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Integration Cost"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("integration_cost", e.target.value)
                }
                value={state.integration_cost ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Operating and Maintenance Cost"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("o_and_m_cost", e.target.value)
                }
                value={state.o_and_m_cost ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.wadt_source_ndx)
              ? "none"
              : "block",
          pageBreakBefore: "always",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Availability</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Willingness of Owners to Sell"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState(
                    "willingness_of_ower_to_sell",
                    e.target.value
                  )
                }
                value={state.willingness_of_ower_to_sell ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Risk of Price Escalation"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("risk_of_price_escalation", e.target.value)
                }
                value={state.risk_of_price_escalation ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Total Shares"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("shares_total", e.target.value)
                }
                value={state.shares_total ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="# Shares not Yet Changed"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("shares_not_changed", e.target.value)
                }
                value={state.shares_not_changed ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="# Shares Owned by Greeley"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("shares_greeley_owned", e.target.value)
                }
                value={state.shares_greeley_owned ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Competition"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("competition", e.target.value)
                }
                value={state.competition ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Availability Notes"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("competition_notes", e.target.value)
                }
                value={state.competition_notes ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.wadt_source_ndx)
              ? "none"
              : "block",
          pageBreakBefore: "always",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Reliability</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Potential Yield Under Shifted Hydrograph"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("yield_under_shifted_hydro", e.target.value)
                }
                value={state.yield_under_shifted_hydro ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Seniority and Reliability"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("seniority_and_reliability", e.target.value)
                }
                value={state.seniority_and_reliability ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Vulnerability to Change"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("vulterability_to_change", e.target.value)
                }
                value={state.vulterability_to_change ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.wadt_source_ndx)
              ? "none"
              : "block",
          pageBreakBefore: "always",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">System Integration</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Integration into Existing System"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState(
                    "integration_into_existing_system",
                    e.target.value
                  )
                }
                value={state.integration_into_existing_system ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Integration into Terry Ranch"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState(
                    "integration_into_terry_ranch",
                    e.target.value
                  )
                }
                value={state.integration_into_terry_ranch ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Time to Implement"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("time_to_implement", e.target.value)
                }
                value={state.time_to_implement ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.wadt_source_ndx)
              ? "none"
              : "block",
          pageBreakBefore: "always",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">
              Transferability (Water Right / Administration Considerations)
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Legal Complexity"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("legal_complexity", e.target.value)
                }
                value={state.legal_complexity ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Ditch/Reservoir Company Considerations"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState(
                    "ditch_reservoir_company_considerations",
                    e.target.value
                  )
                }
                value={state.ditch_reservoir_company_considerations ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                multiline
                rows={1}
                rowsMax={100}
                variant="outlined"
                label="Water Right Operational Flexibility"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState(
                    "water_right_operational_flexibility",
                    e.target.value
                  )
                }
                value={state.water_right_operational_flexibility ?? "N/A"}
                inputProps={{
                  style: {
                    // fontWeight: "bold",
                    fontSize: "16px",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/*<Divider />*/}
      {/*<AccordionActions>*/}
      {/*  <Button size="small" onClick={handleResetState}>*/}
      {/*    Reset*/}
      {/*  </Button>*/}
      {/*  <Button*/}
      {/*    size="small"*/}
      {/*    color="secondary"*/}
      {/*    variant="contained"*/}
      {/*    onClick={handleSubmit}*/}
      {/*    disabled={!isDirty || isFormSubmitting}*/}
      {/*    startIcon={getButtonIcon(isFormSubmitting)}*/}
      {/*    style={{ width: "100px" }}*/}
      {/*  >*/}
      {/*    {getButtonText(isFormSubmitting, selectedWaterSource === 0)}*/}
      {/*  </Button>*/}
      {/*</AccordionActions>*/}
    </>
  );
}

export default BasicWaterSourceInfo;
