import React, { useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { NavLink, useParams } from "react-router-dom";

import BasicWaterSourceInfo from "./BasicWaterSourceInfo";
import { useApp } from "../../../../AppProvider";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

function WaterSourceChangeLogManagement() {
  let { id } = useParams();
  const { currentUser } = useApp();
  const [selectedWaterSource, setSelectedWaterSource] = useState(id);

  return (
    <React.Fragment>
      <Helmet title="WADT Water Source Change Log" />
      <Typography variant="h3" gutterBottom display="inline">
        WADT Water Source Change Log
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={currentUser?.home || "/"}>
          Dashboard
        </Link>
        <Link
          component={NavLink}
          exact
          to="/water-acquisition-tool/water-sources"
        >
          View All Water Source Change Logs
        </Link>
        <Typography>View Water Source Change Log</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <BasicWaterSourceInfo
        selectedWaterSource={selectedWaterSource}
        setSelectedWaterSource={setSelectedWaterSource}
        isEditMode={true}
      />
    </React.Fragment>
  );
}

export default WaterSourceChangeLogManagement;
