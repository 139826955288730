import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

export const useWaterSourcesCompare = (selectedWaterSourcesCompare) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isFetching, error, refetch } = useQuery(
    ["ui-report-sources-change-log/compare", selectedWaterSourcesCompare],
    async () => {
      if (!selectedWaterSourcesCompare.length) {
        return [];
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-report-sources-change-log/compare/${selectedWaterSourcesCompare}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const selectedColors = ["red", "blue", "orange", "green"];

  const categories = [
    {
      title: "General Information",
      properties: [
        "wadt_source_name",
        "wadt_source_abbrev",
        "s_class",
        "reason_for_exclusion", // Conditionally included based on state.s_class
        "water_source",
        "district",
        "diversion_name_fav",
        "potability",
        "water_right_type",
      ],
    },
    {
      title: "Yield",
      properties: ["yieldhdgt_afpsh", "yieldcu_afpsh", "yield_notes"],
    },

    {
      title: "Cost",
      properties: [
        "cost_per_share",
        "cost_per_af",
        "integration_cost",
        "o_and_m_cost",
      ],
    },
    {
      title: "Availability",
      properties: [
        "willingness_of_ower_to_sell",
        "risk_of_price_escalation",
        "shares_total",
        "shares_not_changed",
        "shares_greeley_owned",
        "competition",
        "competition_notes",
      ],
    },
    {
      title: "Reliability",
      properties: [
        "yield_under_shifted_hydro",
        "seniority_and_reliability",
        "vulterability_to_change",
      ],
    },
    {
      title: "System Integration",
      properties: [
        "integration_into_existing_system",
        "integration_into_terry_ranch",
        "time_to_implement",
      ],
    },
    {
      title: "Transferability (Water Right / Administration Considerations)",
      properties: [
        "legal_complexity",
        "ditch_reservoir_company_considerations",
        "water_right_operational_flexibility",
      ],
    },
  ];

  return {
    data,
    isLoading: isFetching,
    error,
    refetch,
    categories,
    selectedColors,
  };
};
