import React from "react";

import Table from "../../../components/Table";
import { useQuery } from "react-query";
import axios from "axios";

const CurrentRivercallsTable = () => {
  const { data, isFetching, error } = useQuery(
    ["/api/dashboard-current-rivercall"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/dashboard-current-rivercall`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const columns = [
    {
      title: "Water Source",
      field: "water_source",
    },
    {
      title: "Structure",
      field: "structure",
    },
    {
      title: "WDID",
      field: "wdid",
    },
    {
      title: "Priority Date",
      field: "priority_date",
    },
    {
      title: "Priority Admin",
      field: "priority_admin",
    },
    {
      title: "Date Set",
      field: "date_set",
    },
    {
      title: "Set Comments",
      field: "set_comments",
    },
  ];

  if (error) return "An error has occurred: " + error.message;

  return (
    <Table
      label="Currently Active River Calls Table"
      isLoading={isFetching}
      columns={columns}
      data={data}
      height="100%"
      options={{
        search: false,
        paging: false,
        toolbar: false,
        padding: "dense",
        headerStyle: {
          fontWeight: "600",
        },
      }}
    />
  );
};

export default CurrentRivercallsTable;
