import React from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Card,
  CardContent,
  CardHeader,
  Typography as MuiTypography,
  useMediaQuery,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { useAuth0 } from "@auth0/auth0-react";
import UsDsBarChart from "./UsDsBarChart/UsDsBarChart";
import useTheme from "@material-ui/core/styles/useTheme";
import CumulativePrecipitationTable from "./CumulativePrecipitationTable";
import CurrentRivercallsTable from "./CurrentRivercallsTable";
import TomorrowWeatherWidget from "./TomorrowWeatherWidget";
import { Link } from "react-router-dom";

const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);

const Hero = styled.div`
  background: url("/static/img/cog-header-entryway.jpg") center center / cover
    no-repeat;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Welcome = styled.div`
  height: 250px;
  background-color: rgba(47, 44, 116, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 26px;
  border-radius: 50%;
  position: absolute;
`;

const GridContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  //grid-auto-rows: 375px;
`;

const WidgetContainer = styled(Card)`
  height: 100%;
  width: 100%;
  grid-column: ${({ gridcolumn }) => gridcolumn};
  grid-row: ${({ gridrow }) => gridrow};
  display: flex;
  flex-direction: column;
  overflow: inherit;
`;

const CardContentTable = styled(CardContent)`
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;

  & > div {
    width: 100%;
  }
`;

const CardContentWeather = styled(CardContent)`
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 325px;
  max-width: 799px;
  width: 100%;
  align-self: center;
  overflow: auto;

  & > div {
    width: 100%;
  }
`;

const useResponsiveLayout = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  return { isSm, isMd };
};

const Accounting = () => {
  const { user } = useAuth0();
  const { isSm, isMd } = useResponsiveLayout();

  return (
    <React.Fragment>
      <Helmet title="Greeley Water Rights & Accounting Dashboard" />
      <Hero>
        <Welcome>
          <Typography variant="h3" gutterBottom style={{ color: "azure" }}>
            Welcome to the
          </Typography>
          <Typography variant="h2" gutterBottom style={{ color: "azure" }}>
            Greeley Water Resources Dashboard
          </Typography>
          <Typography variant="subtitle1" style={{ color: "azure" }}>
            {user?.name}
          </Typography>
        </Welcome>
      </Hero>

      <GridContainer>
        <WidgetContainer gridcolumn="1" gridrow={isSm ? "1" : "1/3"}>
          <CardHeader title="Weather Forecast" />
          <CardContentWeather>
            <TomorrowWeatherWidget />
          </CardContentWeather>
        </WidgetContainer>

        <WidgetContainer
          gridcolumn={isSm ? "1" : isMd ? "2" : "2"}
          gridrow={isSm ? "2" : "1"}
        >
          <CardHeader title="Current Flows, Up-Downstream" />
          <CardContent>
            <UsDsBarChart height={323} />
          </CardContent>
        </WidgetContainer>

        <WidgetContainer
          gridcolumn={isSm ? "1" : isMd ? "2" : "3"}
          gridrow={isSm ? "3" : isMd ? "2" : "1"}
        >
          <CardHeader title="Cumulative Precipitation" />
          <CardContentTable>
            <CumulativePrecipitationTable />
            <Button
              component={Link}
              to="/water-accounting/dashboards/climate-center"
              variant="contained"
              color="secondary"
              mt={2}
            >
              Go to Climate Center
            </Button>
          </CardContentTable>
        </WidgetContainer>

        <WidgetContainer
          gridcolumn={isSm ? "1" : isMd ? "1/3" : "2/4"}
          gridrow={isSm ? "4" : isMd ? "3" : "2"}
        >
          <CardHeader title="Currently Active River Calls" />
          <CardContentTable>
            <CurrentRivercallsTable />
          </CardContentTable>
        </WidgetContainer>
      </GridContainer>
    </React.Fragment>
  );
};
export default Accounting;
