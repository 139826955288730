import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useApp } from "../AppProvider";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export const useSubmitData = (
  selectedItem,
  setSelectedItem,
  editorState,
  endpoint,
  ndxName,
  refetch,
  preSelectedSource
) => {
  const history = useHistory(); // Initialize history
  const { getAccessTokenSilently } = useAuth0();
  const { doToast, currentUser } = useApp();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const handleSubmit = () => {
    if (editorState[ndxName]) {
      handleUpdate().then();
    } else {
      handleAdd().then((data) => {
        if (preSelectedSource) {
          const newPath = `/water-acquisition-tool/acquisitions-and-opportunities/${data.opportunity_ndx}`;

          // Use history.push to navigate to the new page
          history.push(newPath);
        }
      });
    }
  };

  const handleAdd = () => {
    return (async () => {
      try {
        setIsFormSubmitting(true);

        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`,
          {
            ...editorState,
            created_by: currentUser.sub,
            created_timestamp: new Date(),
          },
          { headers }
        );
        setSelectedItem(data?.well_ndx);
        setIsFormSubmitting(false);
        doToast("success", "New entry was saved to the database");
        refetch();
        return data;
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleUpdate = () => {
    return (async () => {
      try {
        if (editorState) {
          setIsFormSubmitting(true);

          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.put(
            `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}/${editorState[ndxName]}`,
            {
              ...editorState,
              last_updated_timestamp: new Date(),
              last_updated: new Date(),
            },
            { headers }
          );
          setIsFormSubmitting(false);
          doToast("success", "New data was updated to the database");
          await refetch();
        } else {
          setIsFormSubmitting(false);
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        setIsFormSubmitting(false);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  return {
    handleSubmit,
    isFormSubmitting,
  };
};
