import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { DEFAULT_WATER_SOURCE_STATE } from "./WaterSourcesConstants";
import { useState } from "react";
import useFetchData from "../../../../hooks/useFetchData";

export const useBasicWaterSourceInfo = (selectedWaterSource) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useState(DEFAULT_WATER_SOURCE_STATE);
  const [isDirty, setIsDirty] = useState(false);

  const handleUpdateState = (name, value) => {
    if (!isDirty) setIsDirty(true);
    setEditorState((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const handleResetState = () => {
    setIsDirty(false);
    if (selectedWaterSource) {
      setEditorState(data);
    } else {
      setEditorState(DEFAULT_WATER_SOURCE_STATE);
    }
  };

  const { data, isFetching, error, refetch } = useQuery(
    ["ui-report-sources", selectedWaterSource],
    async () => {
      if (!selectedWaterSource) {
        setIsDirty(false);
        setEditorState(DEFAULT_WATER_SOURCE_STATE);
        return {};
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-wadt-sources/${selectedWaterSource}`,
          { headers }
        );
        setIsDirty(false);
        setEditorState(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [competitionLookup] = useFetchData(
    "ui-foredit-lookup-competition",
    [],
    true
  );
  const [structuresLookup] = useFetchData(
    "ui-foredit-lookup-structures",
    [],
    true
  );
  const [supplyTypesLookup] = useFetchData(
    "ui-foredit-lookup-supply-types",
    [],
    true
  );
  const [waterdistrictsLookup] = useFetchData(
    "ui-foredit-lookup-waterdistricts",
    [],
    true
  );
  const [waterrightTypesLookup] = useFetchData(
    "ui-foredit-lookup-waterright-types",
    [],
    true
  );
  const [watersourcesLookup] = useFetchData(
    "ui-foredit-lookup-watersources",
    [],
    true
  );
  const [classesLookup] = useFetchData("ui-lookup-wadt-classes", [], true);

  return {
    data,
    isLoading: isFetching,
    error,
    refetch,
    isDirty,
    lookups: {
      competition: competitionLookup,
      structures: structuresLookup,
      supplyTypes: supplyTypesLookup,
      waterdistricts: waterdistrictsLookup,
      waterrightTypes: waterrightTypesLookup,
      watersources: watersourcesLookup,
      classes: classesLookup,
    },
    handleUpdateState,
    handleResetState,
    state: editorState,
    setter: setEditorState,
  };
};
