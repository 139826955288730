import { useQueries } from "react-query";
import axios from "axios";
import { BASE_URL, DEFAULT_FILTERS } from "./constants";
import { useCallback, useState } from "react";
import useFetchData from "../../../hooks/useFetchData";
import { transformDataForBarChart, transformDataForLineChart } from "./utils";

export const useClimateCenter = () => {
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const handleFilterChange = useCallback((name, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  }, []);

  const [yearsLookup] = useFetchData("climate-center/years");

  const fetchData = async (url) => {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error(`Error fetching ${url} data:`, error);
      throw error;
    }
  };

  const createQuery = (dataType) => ({
    queryKey: [`${dataType}Data`, filters.year],
    queryFn: () => fetchData(`${BASE_URL}/${dataType}/${filters.year}`),
    enabled: !!filters.year,
    refetchOnWindowFocus: false,
  });

  const queriesBySelectedYear = ["daily", "monthly", "table"].map((dataType) =>
    createQuery(dataType)
  );

  const results = useQueries(queriesBySelectedYear);

  const dataDaily = results?.[0]?.data
    ? transformDataForLineChart(results[0].data)
    : null;
  const dataMonthly = results?.[1]?.data
    ? transformDataForBarChart(results[1].data)
    : null;
  const dataTable = results[2].data;

  const isLoading = results.some((query) => query.isLoading);
  const error = results.find((query) => query.error);

  return {
    yearsLookup,
    dataDaily,
    dataMonthly,
    dataTable,
    error,
    isLoading,
    handleFilterChange,
    filters,
    setFilters,
  };
};
