import React, { useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Typography as MuiTypography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useApp } from "../../../../AppProvider";
import { useRiverCalls } from "./useRiverCalls";
import { ISO_DATE_FORMAT } from "./constants";
import { dateFormatter } from "../../ClimateCenter/utils";
import Loader from "../../../../components/Loader";
import Table from "../../../../components/Table";
import { getLookupForDataSortedByField } from "../../../../utils";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const OptionsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TableWrapper = styled(Box)`
  width: 100%;
`;

const RiverCalls = () => {
  const { currentUser } = useApp();

  const {
    waterSourcesLookup,
    data,
    error,
    isLoading,
    handleFilterChange,
    filters,
  } = useRiverCalls();

  const COLUMNS = useMemo(
    () => [
      {
        title: "Date",
        field: "tsd_date",
        type: "date",
        render: (rowData) => dateFormatter(rowData.tsd_date, ISO_DATE_FORMAT),
      },
      { title: "WDID", field: "wdid" },
      {
        title: "Structure",
        field: "structure_name",
      },
      {
        title: "Call Type",
        field: "call_type",
        lookup: getLookupForDataSortedByField(data, "call_type"),
      },
      {
        title: "Call Start",
        field: "call_start",
        render: (rowData) =>
          rowData.call_end === "ACTIVE"
            ? rowData.call_start
            : dateFormatter(rowData.call_start, ISO_DATE_FORMAT),
        cellStyle: {
          width: 125,
          minWidth: 125,
        },
      },
      {
        title: "Call End",
        field: "call_end",
        render: (rowData) =>
          rowData.call_end === "ACTIVE"
            ? rowData.call_end
            : dateFormatter(rowData.call_end, ISO_DATE_FORMAT),
        cellStyle: {
          width: 125,
          minWidth: 125,
        },
      },
      { title: "Admin #", field: "admin_no" },
      {
        title: "Appropriation Date",
        field: "appro_date",
      },
      { title: "Comments", field: "set_comments" },
      { title: "Water Source", field: "water_source" },
    ],
    [data] //eslint-disable-line
  );

  if (error) return "An error has occurred: " + error?.error?.message;
  return (
    <>
      <Helmet title="River Calls" />
      <Typography variant="h3" gutterBottom display="inline">
        River Calls
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={currentUser?.home || "/"}>
          Dashboard
        </Link>
        <Typography>River Calls</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="options"
          id="options"
        >
          <Typography variant="h4">Options</Typography>
        </AccordionSummary>
        <Paper>
          <AccordionDetails>
            <OptionsContainer>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "400px" }}
              >
                {!waterSourcesLookup.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="waterSource">Water Source</InputLabel>
                    <Select
                      labelId="waterSource"
                      id="waterSource"
                      label="Water Source"
                      name="waterSource"
                      value={filters.waterSource ?? ""}
                      onChange={(e) => {
                        handleFilterChange("waterSource", e.target.value);
                      }}
                    >
                      {waterSourcesLookup.map(
                        ({ watershed_ndx, waterbody }) => (
                          <MenuItem key={watershed_ndx} value={watershed_ndx}>
                            {waterbody}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </>
                )}
              </FormControl>
            </OptionsContainer>
          </AccordionDetails>
        </Paper>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="table-content"
          id="table-header"
        >
          <Typography variant="h4">Table</Typography>
        </AccordionSummary>
        <Paper>
          <AccordionDetails>
            <TableWrapper>
              <Table
                isLoading={isLoading}
                label="River Calls"
                columns={COLUMNS}
                data={data}
                height="600px"
                options={{ search: false, width: "100%", filtering: true }}
                pageSize={60}
              />
            </TableWrapper>
          </AccordionDetails>
        </Paper>
      </Accordion>
    </>
  );
};
export default RiverCalls;
