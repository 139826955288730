import axios from "axios";
import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { USE_QUERY_OPTIONS } from "./constants";
import * as yup from "yup";

export const generateAuthHeaders = async (
  getAccessTokenSilently,
  isAuthenticated
) => {
  let headers = {};
  if (isAuthenticated) {
    try {
      const token = await getAccessTokenSilently();
      headers["Authorization"] = `Bearer ${token}`;
    } catch (error) {
      console.error("Error getting access token:", error);
    }
  }
  return headers;
};

const getData = async (url, headers = {}) => {
  try {
    const { data } = await axios.get(url, { headers });
    return data;
  } catch (error) {
    throw new Error(`Failed to fetch data: ${error.message}`);
  }
};

export const useCustomQuery = (key, endpoint) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const fetchConditionallyWithToken = async () => {
    const headers = await generateAuthHeaders(
      getAccessTokenSilently,
      isAuthenticated
    );

    return getData(
      `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`,
      headers
    );
  };

  return useQuery(key, fetchConditionallyWithToken, USE_QUERY_OPTIONS);
};

export const triggerDownload = (
  blob,
  filename = `data_download_${new Date()}.csv`
) => {
  const blobUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(blobUrl);
};

// This function updates the values for a specific field in a form based on filtered data. It ensures that only those
// values that match the criteria represented by the filtered data are retained in the form field. This function is
// particularly useful for dynamically adjusting form selections based on changes in related fields or external
// filters. It removes any selections that are no longer valid given the current state of filtered data.
export const updateFilteredSelections = (
  field,
  filteredData,
  getId = (item) => item.id,
  getValues,
  setValue
) => {
  const currentValues = getValues(field);
  const newValues = currentValues.filter((value) =>
    filteredData.some((item) => getId(item) === value)
  );

  if (newValues.length !== currentValues.length) {
    setValue(field, newValues);
  }
};

// This function checks whether an item's associated parameters matches the currently selected parameters. If a
// selectedParameters is provided, it returns true if the item's associated parameters includes any of the selected parameters, otherwise
// true by default, implying that without a specific filter, all items are considered to match.
const matchesSelectedParameters = (item, selectedParameters) => {
  if ([null, undefined].includes(selectedParameters)) return true;
  const selectedParametersSet = new Set(selectedParameters);
  return item.assoc_parameter_ndx.some((parameter) =>
    selectedParametersSet.has(parameter)
  );
};

// This function filters a dataset based on given criteria (a mapping of field names to expected values) and an
// optional selected data category. It first filters items that match all the provided criteria, and then further
// filters those items based on whether they match the selected data category, if one is provided. This function is
// useful for applying multiple filters to a dataset, where the filters might include both specific field values and
// broader category inclusion.
export const filterDataWithCriteria = (data, criteria, selectedParameters) => {
  return (
    data?.filter((item) => {
      const matchesCriteria = Object.entries(criteria).every(([key, values]) =>
        values.includes(item[key])
      );
      return (
        matchesCriteria && matchesSelectedParameters(item, selectedParameters)
      );
    }) || []
  );
};

// This function filters a dataset to include only those items that match a given selected data category. If no
// specific category is selected, it returns all items. This is a specialized filter function focusing solely on the
// aspect of categorization based on a selectedDataCategory, making it useful for scenarios where the filtering needs
// to be based solely on an item's associated category.
export const filterBySelectedParameters = (data, selectedParameters) => {
  return (
    data?.filter((item) =>
      matchesSelectedParameters(item, selectedParameters)
    ) || []
  );
};

export const nonEmptyArray = (
  message = "This field is required and cannot be empty"
) =>
  yup
    .array()
    .of(yup.number().integer().required("A value is required"))
    .required(message)
    .test(
      "is-non-empty",
      message,
      (value) => Array.isArray(value) && value.length > 0
    );

// checks to see if form is filled out to allow count fetch
export const hasEmptyValues = (values) => {
  return Object.values(values).some((value) => {
    if (Array.isArray(value)) return value.length === 0;
    if (typeof value === "string") return value.trim() === "";
    return false;
  });
};
