import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { useEffect } from "react";

import Loader from "../../../../components/Loader";
import { useSubmitData } from "../../../../hooks/useSubmitData";
import { useEvaulationForm } from "./useEvaulationForm";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getButtonIcon, getButtonText } from "./utils";
import InfoIcon from "@material-ui/icons/Info";
import { titleize } from "inflected";
import { categories } from "./AcquisitionsAndOpportunitiesConstants";
import { Alert } from "@material-ui/lab";

function EvaluationForm({
  preSelectedSource = null,
  selectedOpportunity,
  setSelectedOpportunity,
  refetchOpportunities = () => {},
  isEditMode = false,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
    lookups,
    baselines,
  } = useEvaulationForm(selectedOpportunity);

  const refetchTopAndBottom = () => {
    refetch().then(() => refetchOpportunities());
  };

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedOpportunity,
    setSelectedOpportunity,
    state,
    "data-opportunities",
    "opportunity_ndx",
    refetchTopAndBottom,
    preSelectedSource
  );

  useEffect(() => {
    if (preSelectedSource) {
      handleUpdateState("wadt_source_ndx", preSelectedSource);
    }
  }, [selectedOpportunity, preSelectedSource]); //eslint-disable-line

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.opportunity_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.opportunity_ndx)
              ? "none"
              : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">
              Acquisition & Opportunity Evaluation Form
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              xl={9}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={!state.wadt_source_ndx}
                required
              >
                {!lookups?.sources.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="wadt_source_ndx">
                      Selected Water Source for this Opportunity
                    </InputLabel>
                    <Select
                      disabled={!!state?.opportunity_ndx}
                      labelId="wadt_source_ndx"
                      id="wadt_source_ndx"
                      label="Selected Water Source for this Opportunity"
                      name="precinct_num"
                      value={state.wadt_source_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("wadt_source_ndx", e.target.value);
                      }}
                    >
                      {lookups.sources.map((option) => (
                        <MenuItem
                          key={option.wadt_source_ndx}
                          value={option.wadt_source_ndx}
                        >
                          {option.wadt_source_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {!state.wadt_source_ndx && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                component="fieldset"
                style={{ marginRight: "10px", width: "100%" }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disabled={!!selectedOpportunity}
                    value={state?.created_date}
                    onChange={(date) => handleUpdateState("created_date", date)}
                    id="created-on"
                    variant="inline"
                    format="LLLL do, yyyy"
                    autoOk
                    inputVariant="outlined"
                    label="Created Date"
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                disabled
                variant="outlined"
                label="Opportunity ID"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("opportunity_id", e.target.value)
                }
                value={state.opportunity_id ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  error={!state.reviewed_by}
                  required
                  variant="outlined"
                  label="Reviewer"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleUpdateState("reviewed_by", e.target.value);
                  }}
                  value={state?.reviewed_by ?? ""}
                />
                {!state.reviewed_by ? (
                  <FormHelperText error>*Required field</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.recordStatus.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="record-status">Record Status</InputLabel>
                    <Select
                      labelId="record status"
                      id="record-status"
                      label="Record Status"
                      name="opp_record_status_ndx"
                      value={state.opp_record_status_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState(
                          "opp_record_status_ndx",
                          e.target.value
                        );
                      }}
                    >
                      {lookups.recordStatus.map((option) => (
                        <MenuItem
                          key={option.opp_record_status_ndx}
                          value={option.opp_record_status_ndx}
                        >
                          {option.opp_record_status_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                component="fieldset"
                style={{ marginRight: "10px", width: "100%" }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disabled
                    value={state?.last_updated}
                    onChange={(date) => handleUpdateState("last_updated", date)}
                    id="last-updated-on"
                    variant="inline"
                    format="LLLL do, yyyy"
                    autoOk
                    inputVariant="outlined"
                    label="Last Updated Date"
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  error={!state.opportunity_name}
                  required
                  placeholder="Internal name for the acquisition"
                  variant="outlined"
                  label="Opportunity Name"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleUpdateState("opportunity_name", e.target.value);
                  }}
                  value={state?.opportunity_name ?? ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tooltip
                          title={
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              Internal name for the acquisition
                            </Box>
                          }
                        >
                          <InfoIcon color="primary" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                {!state.opportunity_name ? (
                  <FormHelperText error>*Required field</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  error={!state.purchaser}
                  required
                  variant="outlined"
                  label="Purchaser"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleUpdateState("purchaser", e.target.value);
                  }}
                  value={state?.purchaser ?? ""}
                />
                {!state.purchaser ? (
                  <FormHelperText error>*Required field</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  error={!state.offerer}
                  required
                  placeholder="Name (First Last) of person and/or entity making the offer"
                  variant="outlined"
                  label="Offerer"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleUpdateState("offerer", e.target.value);
                  }}
                  value={state?.offerer ?? ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tooltip
                          title={
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              Name (First Last) of person and/or entity making
                              the offer
                            </Box>
                          }
                        >
                          <InfoIcon color="primary" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                {!state.offerer ? (
                  <FormHelperText error>*Required field</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={8}
              style={{
                position: "relative",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <TextField
                  variant="outlined"
                  label="Offerer Contact Info"
                  placeholder="phone / email / mailing address"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleUpdateState("offerer_contact_info", e.target.value);
                  }}
                  value={state?.offerer_contact_info ?? ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tooltip
                          title={
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              phone / email / mailing address
                            </Box>
                          }
                        >
                          <InfoIcon color="primary" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={!state.opp_type_ndx}
                required
              >
                {!lookups?.type.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="type">Type</InputLabel>
                    <Select
                      labelId="type"
                      id="type"
                      label="type"
                      name="opp_type_ndx"
                      value={state.opp_type_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("opp_type_ndx", e.target.value);
                      }}
                    >
                      {lookups.type.map((option) => (
                        <MenuItem
                          key={option.opp_type_ndx}
                          value={option.opp_type_ndx}
                        >
                          {option.opp_type_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {!state.opp_type_ndx && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <TextField
                  type="number"
                  variant="outlined"
                  label="# of Shares / Contract Rights"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      handleUpdateState("num_shares", null);
                    } else {
                      handleUpdateState("num_shares", +e.target.value);
                    }
                  }}
                  value={state?.num_shares ?? ""}
                  InputProps={{
                    inputProps: {
                      onKeyPress: (e) => {
                        if (e.key === "e") {
                          e.preventDefault();
                        }
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <TextField
                  type="number"
                  variant="outlined"
                  label="Initial Offer Price (per share)"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    // Ensure the input is numeric with up to 2 decimal places
                    const inputValue = e.target.value;
                    const numericValue = parseFloat(inputValue);

                    if (!isNaN(numericValue)) {
                      // Use toFixed(2) to limit to 2 decimal places
                      const roundedValue = parseFloat(numericValue.toFixed(2));
                      handleUpdateState("price_per_share", roundedValue);
                    } else {
                      handleUpdateState("price_per_share", null);
                    }
                  }}
                  value={state?.price_per_share ?? ""}
                  InputProps={{
                    inputProps: {
                      onKeyPress: (e) => {
                        if (e.key === "e") {
                          e.preventDefault();
                        }
                      },
                    },
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <TextField
                  type="number"
                  variant="outlined"
                  label="Initial Offer Price (Total)"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    // Ensure the input is numeric with up to 2 decimal places
                    const inputValue = e.target.value;
                    const numericValue = parseFloat(inputValue);

                    if (!isNaN(numericValue)) {
                      // Use toFixed(2) to limit to 2 decimal places
                      const roundedValue = parseFloat(numericValue.toFixed(2));
                      handleUpdateState("price_total", roundedValue);
                    } else {
                      handleUpdateState("price_total", null);
                    }
                  }}
                  value={state?.price_total ?? ""}
                  InputProps={{
                    inputProps: {
                      onKeyPress: (e) => {
                        if (e.key === "e") {
                          e.preventDefault();
                        }
                      },
                    },
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.status.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="record-status">Offer Status</InputLabel>
                    <Select
                      labelId="offer status"
                      id="offer-status"
                      label="Offer Status"
                      name="opp_status_ndx"
                      value={state.opp_status_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("opp_status_ndx", e.target.value);
                      }}
                    >
                      {lookups.status.map((option) => (
                        <MenuItem
                          key={option.opp_status_ndx}
                          value={option.opp_status_ndx}
                        >
                          {option.opp_status_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={9}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  variant="outlined"
                  label="Offer/Price notes"
                  placeholder="Document notes related to offer price"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleUpdateState("offer_price_notes", e.target.value);
                  }}
                  value={state?.offer_price_notes ?? ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tooltip
                          title={
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              Document notes related to offer price
                            </Box>
                          }
                        >
                          <InfoIcon color="primary" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  error={!state.description_of_location}
                  required
                  placeholder="UTM or Township/Range/Section"
                  variant="outlined"
                  label="Description of Location"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleUpdateState(
                      "description_of_location",
                      e.target.value
                    );
                  }}
                  value={state?.description_of_location ?? ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tooltip
                          title={
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              UTM or Township/Range/Section
                            </Box>
                          }
                        >
                          <InfoIcon color="primary" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                {!state.description_of_location ? (
                  <FormHelperText error>*Required field</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  multiline
                  rows={2}
                  placeholder="Provide additional relevant information not covered by other fields"
                  variant="outlined"
                  label="Relevant History Associated with Water Right"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleUpdateState("relevant_history", e.target.value);
                  }}
                  value={state?.relevant_history ?? ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tooltip
                          title={
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              Provide additional relevant information not
                              covered by other fields
                            </Box>
                          }
                        >
                          <InfoIcon color="primary" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.recommendation.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="wr-recommendation">
                      WR Recommendation
                    </InputLabel>
                    <Select
                      labelId="wr recommendation"
                      id="wr-recommendation"
                      label="WR Recommendation"
                      name="opp_recommendation_ndx"
                      value={state.opp_recommendation_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState(
                          "opp_recommendation_ndx",
                          e.target.value
                        );
                      }}
                    >
                      {lookups.recommendation.map((option) => (
                        <MenuItem
                          key={option.opp_recommendation_ndx}
                          value={option.opp_recommendation_ndx}
                        >
                          {option.opp_recommendation_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={9}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  multiline
                  rows={3}
                  variant="outlined"
                  label="WR Recommendation Rationale"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleUpdateState(
                      "opp_recommendation_notes",
                      e.target.value
                    );
                  }}
                  value={state?.opp_recommendation_notes ?? ""}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.decision.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="board-decision">Board Decision</InputLabel>
                    <Select
                      labelId="board decision"
                      id="board-decision"
                      label="Board Decision"
                      name="opp_decision_ndx"
                      value={state.opp_decision_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("opp_decision_ndx", e.target.value);
                      }}
                    >
                      {lookups.decision.map((option) => (
                        <MenuItem
                          key={option.opp_decision_ndx}
                          value={option.opp_decision_ndx}
                        >
                          {option.opp_decision_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={9}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  multiline
                  rows={3}
                  variant="outlined"
                  label="Board Decision Rationale"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleUpdateState("opp_decision_notes", e.target.value);
                  }}
                  value={state?.opp_decision_notes ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <Typography
                variant="h3"
                style={{ textDecoration: "underline", marginBottom: "10px" }}
              >
                Baseline Source Reference
              </Typography>
              <Button size="large" variant="contained" color="primary">
                View Water Rights Tabulation
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <Typography variant="h3" style={{ textDecoration: "underline" }}>
                Notes, Rationale and Special Considerations
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              {!!baselines?.data ? (
                <>
                  {categories.map((category, catIndex) => {
                    return (
                      <Grid container spacing={3} key={catIndex}>
                        <Grid item xs={6}>
                          <Accordion
                            style={{
                              display: isLoading ? "none" : "block",
                              pageBreakBefore: "always",
                            }}
                          >
                            <AccordionSummary expandIcon={<ExpandMore />}>
                              <Box style={{ width: "100%" }}>
                                <Typography variant="h3">
                                  {category.title}
                                </Typography>
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={2}>
                                {baselines?.data &&
                                  Object.keys(baselines?.data)
                                    .filter(
                                      (item) =>
                                        category.properties.includes(item) ||
                                        item === "wadt_source_name"
                                    )
                                    .map((key, i) => {
                                      return (
                                        <React.Fragment key={key}>
                                          {/* Header */}
                                          <Grid item xs={12}>
                                            <Typography
                                              variant="h5"
                                              style={{
                                                fontWeight:
                                                  i === 0 ? "900" : "normal",
                                              }}
                                            >
                                              {titleize(key)}
                                            </Typography>
                                          </Grid>
                                          {/* Data */}
                                          <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                          >
                                            <div
                                              style={{
                                                border: `3px solid black`,
                                                marginRight: "3px",
                                                padding: "10px",
                                                flexGrow: 1,
                                                width: "50%",
                                              }}
                                              key={baselines?.data[key]}
                                            >
                                              <Typography
                                                style={{
                                                  fontSize: "16px",
                                                  color: "black",
                                                  fontWeight:
                                                    key === "wadt_source_name"
                                                      ? "600"
                                                      : "normal",
                                                }}
                                              >
                                                {baselines?.data[key]}
                                              </Typography>
                                            </div>
                                          </Grid>
                                        </React.Fragment>
                                      );
                                    })}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            <TextField
                              variant="outlined"
                              label={category.label}
                              style={{ width: "100%" }}
                              onChange={(e) => {
                                handleUpdateState(
                                  category.field,
                                  e.target.value
                                );
                              }}
                              value={state?.[category.field] ?? ""}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    );
                  })}
                </>
              ) : (
                <Alert mb={4} severity="info">
                  Select a Water Source to view Baseline Source Reference and to
                  add Notes, Rationale and Special Considerations
                </Alert>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={handleResetState}>
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={
              !isDirty ||
              isFormSubmitting ||
              !state.wadt_source_ndx ||
              !state.reviewed_by ||
              !state.opportunity_name ||
              !state.purchaser ||
              !state.offerer ||
              !state.opp_type_ndx ||
              !state.description_of_location
            }
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting, selectedOpportunity === 0)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default EvaluationForm;
