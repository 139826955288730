import React, { useCallback, useRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Link,
  Paper,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import MaterialTable from "material-table";
import CopyIcon from "@material-ui/icons/FileCopy";
import { ExpandMore } from "@material-ui/icons";

import { NavLink } from "react-router-dom";
import { useApp } from "../../../../AppProvider";

import {
  copyToClipboard,
  dateFormatter,
  scrollToTarget,
} from "../../../../utils";
import EvaluationForm from "./EvaluationForm";
import { useAcquisitionsAndOpportunities } from "./useAcquisitionsAndOpportunities";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;
function AcquisitionsAndOpportunitiesManagement() {
  const {
    selectedOpportunity,
    setSelectedOpportunity,
    data,
    isFetching,
    error,
    refetch,
    SUMMARY_TABLE_COLUMNS,
  } = useAcquisitionsAndOpportunities();
  const { doToast, currentUser } = useApp();
  const { getAccessTokenSilently } = useAuth0();
  const readSaveRef = useRef(null);

  const handleScrollToReadSave = useCallback(() => {
    setTimeout(() => scrollToTarget(readSaveRef), 1000);
  }, []);

  const handleDelete = (oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.delete(
            `${process.env.REACT_APP_ENDPOINT}/api/data-opportunities/${oldData.opportunity_ndx}`,
            { headers }
          );
          doToast("success", "This entry was deleted from the database");
          if (selectedOpportunity === oldData.opportunity_ndx) {
            setSelectedOpportunity(null);
          }

          await refetch();
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const getRowStyle = React.useCallback((rowData) => {
    return {
      backgroundColor:
        rowData.opp_record_status_ndx === 1 ? "#ffdee2" : "inherit",
    };
  }, []);

  if (error) return "An error has occurred: " + error.message;
  return (
    <React.Fragment>
      <Helmet title="Water Acquisitions & Opportunities Log" />
      <Typography variant="h3" gutterBottom display="inline">
        Water Acquisitions & Opportunities Log
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={currentUser?.home || "/"}>
          Dashboard
        </Link>
        <Typography>Water Acquisitions & Opportunities Log</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Box>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box style={{ width: "100%" }}>
              <Typography variant="h3">
                Summary of Acquisition Opportunities
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <TableWrapper>
              <MaterialTable
                id="Summary of Acquisition Opportunities"
                title={`Summary of Acquisition Opportunities ${dateFormatter(
                  new Date(),
                  "MM/DD/YYYY, h:mm A"
                )}`}
                loading={isFetching || !data}
                columns={SUMMARY_TABLE_COLUMNS}
                data={data}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Acquisition & Opportunity Selection",
                  },
                }}
                components={{
                  Container: (props) => <div {...props} />,
                }}
                editable={{
                  onRowDelete: handleDelete,
                }}
                actions={[
                  {
                    icon: CopyIcon,
                    tooltip: "Copy Data",
                    isFreeAction: true,
                    onClick: () => {
                      try {
                        copyToClipboard(data, SUMMARY_TABLE_COLUMNS, () =>
                          doToast(
                            "success",
                            "Data was copied to your clipboard."
                          )
                        );
                      } catch (error) {
                        const message =
                          error?.message ?? "Something went wrong";
                        doToast("error", message);
                      }
                    },
                  },
                  () => ({
                    icon: "edit",
                    tooltip: "Edit",
                    onClick: (event, rowData) => {
                      setSelectedOpportunity(rowData?.opportunity_ndx);
                      handleScrollToReadSave();
                    },
                  }),
                  {
                    icon: "add_box",
                    tooltip: "Add",
                    isFreeAction: true,
                    onClick: () => {
                      setSelectedOpportunity(0);
                      handleScrollToReadSave();
                    },
                  },
                ]}
                options={{
                  filtering: true,
                  emptyRowsWhenPaging: false,
                  showTitle: false,
                  columnsButton: true,
                  exportButton: true,
                  exportAllData: true,
                  addRowPosition: "first",
                  pageSize: 10,
                  pageSizeOptions: [10, 30, 50, 100, 200],
                  padding: "dense",
                  searchFieldAlignment: "left",
                  minBodyHeight: "250px",
                  maxBodyHeight:
                    "calc(100vh - 64px - 48px - 106px - 48px - 64px - 204px - 100px)",
                  rowStyle: getRowStyle,
                }}
              />
            </TableWrapper>
          </AccordionDetails>
        </Accordion>

        <Box
          style={{
            display:
              selectedOpportunity || selectedOpportunity === 0
                ? "block"
                : "none",
          }}
        >
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4">
                {selectedOpportunity === 0
                  ? "Creating New Acquisition & Opportunity"
                  : "Editing Existing Acquisition & Opportunity"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedOpportunity(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
          <div ref={readSaveRef}>
            <EvaluationForm
              selectedOpportunity={selectedOpportunity}
              setSelectedOpportunity={setSelectedOpportunity}
              refetchOpportunities={refetch}
            />
          </div>
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4">
                {selectedOpportunity === 0
                  ? "Creating New Acquisition & Opportunity"
                  : "Editing Existing Acquisition & Opportunity"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedOpportunity(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default AcquisitionsAndOpportunitiesManagement;
