import React, { useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { NavLink, useParams } from "react-router-dom";

import EvaluationForm from "./EvaluationForm";
import { useApp } from "../../../../AppProvider";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

function AcquisitionAndOpportunityManagement() {
  let { id } = useParams();
  const { currentUser } = useApp();
  const [selectedOpportunity, setSelectedOpportunity] = useState(id);

  return (
    <React.Fragment>
      <Helmet title="Water Acquisitions & Opportunities Log" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Acquisition & Opportunity
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={currentUser?.home || "/"}>
          Dashboard
        </Link>
        <Link
          component={NavLink}
          exact
          to="/water-acquisition-tool/acquisitions-and-opportunities"
        >
          Summary of Acquisition Opportunities
        </Link>
        <Typography>Edit Acquisition & Opportunity</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <EvaluationForm
        selectedOpportunity={selectedOpportunity}
        setSelectedOpportunity={setSelectedOpportunity}
        isEditMode={true}
      />
    </React.Fragment>
  );
}

export default AcquisitionAndOpportunityManagement;
