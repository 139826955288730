import React from "react";
import SystemGraphs from "./SystemGraphs";

const PoudrePondsScadaViewer = () => {
  const defaultParameter = 1;

  const pageTitle = "Poudre Ponds SCADA Viewer";

  const endpoints = [
    { label: "30 Minutes", value: "display-scada" },
    { label: "Daily", value: "display-scada-daily" },
  ];

  const parametersEndpoint = "display-scada-lookup-parameter";

  return (
    <>
      <SystemGraphs
        defaultParameter={defaultParameter}
        pageTitle={pageTitle}
        endpoints={endpoints}
        parametersEndpoint={parametersEndpoint}
      />
    </>
  );
};

export default PoudrePondsScadaViewer;
