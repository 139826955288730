import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUDIENCE;

  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      scope="read:current_user update:current_user create:user_tickets read:logs read:logs_users read:roles read:users update:users read:role_members update:current_user_metadata read:current_user_metadata"
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <HandleRedirection>{children}</HandleRedirection>
    </Auth0Provider>
  );
};

const HandleRedirection = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    const getAccessTokenAndRedirect = async () => {
      if (isAuthenticated) {
        const loginInitiated = localStorage.getItem("loginInitiated"); // Retrieve the flag

        // Only proceed if the login was initiated by a user action
        if (loginInitiated === "true") {
          localStorage.removeItem("loginInitiated"); // Clear the flag

          try {
            const accessToken = await getAccessTokenSilently();
            const decodedToken = jwt_decode(accessToken);
            const roles =
              decodedToken[`${process.env.REACT_APP_AUDIENCE}/roles`] || [];

            // Conditional redirection based on the roles
            if (
              roles.some((role) =>
                [
                  "Accounting Admin",
                  "Accounting User",
                  "Accounting Viewer",
                ].includes(role)
              )
            ) {
              history.push("/home/accounting");
            } else {
              history.push("/water-acquisition-tool/wadt-map");
            }
          } catch (error) {
            console.error("Error getting access token", error);
            // Handle the error appropriately
          }
        }
      }
    };

    getAccessTokenAndRedirect();
  }, [isAuthenticated, getAccessTokenSilently, history]);

  return <>{children}</>;
};

export default Auth0ProviderWithHistory;
