import React, { useMemo } from "react";
import { useQuery } from "react-query";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import Table from "../../components/Table";
import { spacing } from "@material-ui/system";
import Panel from "../../components/panels/Panel";
import { Helmet } from "react-helmet-async";
import { dateFormatter } from "../../utils";
import { getLookupForDataSortedByField } from "../../utils";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useApp } from "../../AppProvider";

const TableWrapper = styled.div`
  width: 100%;
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

//388px
const SiteSummaryTable = () => {
  const { currentUser } = useApp();

  const { data, isFetching, error } = useQuery(
    ["/api/report-site-summary-table"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/report-site-summary-table`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  // const [Facilities] = useFetchData("list-facilities-united-only", [], true);
  // const facilitiesLookup = useMemo(() => {
  //   let converted = {};
  //   if (Facilities?.length > 0) {
  //     Facilities.forEach((item) => {
  //       converted[item.facility_name] = item.facility_name;
  //     });
  //   }
  //   return converted;
  // }, [Facilities]);

  const tabColumns = useMemo(
    () => [
      {
        title: "Source",
        field: "data_source",
        lookup: getLookupForDataSortedByField(data, "data_source"),
        cellStyle: {
          width: 175,
          minWidth: 175,
        },
      },
      {
        title: "Waterbody",
        field: "waterbody",
        lookup: getLookupForDataSortedByField(data, "waterbody"),
        cellStyle: {
          width: 175,
          minWidth: 175,
        },
      },
      {
        title: "Location ID",
        field: "location_id",
        cellStyle: {
          width: 200,
          minWidth: 200,
        },
      },
      {
        title: "Parameter",
        field: "parameter",
        lookup: getLookupForDataSortedByField(data, "parameter"),
      },
      {
        title: "Last Value",
        field: "last_reported_value",
      },
      {
        title: "Last Date",
        field: "last_report",
        render: (rowData) => {
          return dateFormatter(rowData.last_report, "MM/DD/YYYY");
        },
      },
      {
        title: "Raw Units",
        field: "incoming_units",
      },
      {
        title: "Final Units",
        field: "official_units",
      },
      {
        title: "Rivermile",
        field: "location_rivermile",
      },
      {
        title: "Location Name",
        field: "location_name",
        cellStyle: {
          width: 500,
          minWidth: 500,
        },
      },
    ],
    [data]
  );

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <Helmet title="Site Summary Table" />
      <Typography variant="h3" gutterBottom display="inline">
        Site Summary Table
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={currentUser?.home || "/"}>
          Dashboard
        </Link>
        <Typography>Site Summary Table</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <Panel>
        <TableWrapper>
          <Table
            label="Site Summary Table"
            isLoading={isFetching}
            columns={tabColumns}
            data={data}
            height="calc(100vh - 485px)"
            pageSize={60}
            options={{ filtering: true, search: false, minBodyHeight: "400px" }}
          />
        </TableWrapper>
      </Panel>
    </>
  );
};
export default SiteSummaryTable;
