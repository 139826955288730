import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import useFetchData from "../../../../hooks/useFetchData";
import { DEFAULT_EVALUATION_FORM_STATE } from "./AcquisitionsAndOpportunitiesConstants";
import { useState } from "react";

export const useEvaulationForm = (selectedOpportunity) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useState(DEFAULT_EVALUATION_FORM_STATE);
  const [isDirty, setIsDirty] = useState(false);

  const handleUpdateState = (name, value) => {
    if (!isDirty) setIsDirty(true);
    setEditorState((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const handleResetState = () => {
    setIsDirty(false);
    if (selectedOpportunity) {
      setEditorState(data);
    } else {
      setEditorState(DEFAULT_EVALUATION_FORM_STATE);
    }
  };

  const { data, isFetching, error, refetch } = useQuery(
    ["data-opportunities", selectedOpportunity],
    async () => {
      if (!selectedOpportunity) {
        setIsDirty(false);
        setEditorState(DEFAULT_EVALUATION_FORM_STATE);
        return {};
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-opportunities/${selectedOpportunity}`,
          { headers }
        );
        setIsDirty(false);
        setEditorState(data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: baselineData,
    isFetching: isFetchingBaseline,
    error: errorBaseline,
    refetch: refetchBaseline,
  } = useQuery(
    [
      "ui-opportunity-baseline-info",
      editorState.wadt_source_ndx,
      selectedOpportunity,
    ],
    async () => {
      if (!editorState.wadt_source_ndx) {
        return null;
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        let endpoint = `${process.env.REACT_APP_ENDPOINT}/api/ui-new-opportunity-baseline-info/${editorState.wadt_source_ndx}`;
        if (selectedOpportunity) {
          endpoint = `${process.env.REACT_APP_ENDPOINT}/api/ui-edit-opportunity-baseline-info/${editorState.wadt_source_ndx}/${selectedOpportunity}`;
        }

        const { data } = await axios.get(endpoint, { headers });
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [sourcesLookup] = useFetchData("ui-lookup-wadt-sources", [], true);
  const [recordStatusLookup] = useFetchData("list-opp-record-status", [], true);
  const [typeLookup] = useFetchData("list-opp-type", [], true);
  const [statusLookup] = useFetchData("list-opp-status", [], true);
  const [recommendationLookup] = useFetchData(
    "list-opp-recommendation",
    [],
    true
  );
  const [decisionLookup] = useFetchData("list-opp-decision", [], true);

  return {
    data,
    isLoading: isFetching,
    error,
    refetch,
    isDirty,
    lookups: {
      sources: sourcesLookup,
      recordStatus: recordStatusLookup,
      type: typeLookup,
      status: statusLookup,
      recommendation: recommendationLookup,
      decision: decisionLookup,
    },
    baselines: {
      data: baselineData,
      isLoading: isFetchingBaseline,
      error: errorBaseline,
      refetch: refetchBaseline,
    },
    handleUpdateState,
    handleResetState,
    state: editorState,
    setter: setEditorState,
  };
};
