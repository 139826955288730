export const DEFAULT_EVALUATION_FORM_STATE = {
  opportunity_ndx: null,
  wadt_source_ndx: null,
  created_date: new Date(),
  opportunity_id: null,
  reviewed_by: null,
  opp_record_status_ndx: 1,
  last_updated: new Date(),
  opportunity_name: null,
  purchaser: "Greeley",
  offerer: null,
  offerer_contact_info: null,
  opp_type_ndx: null,
  num_shares: null,
  price_per_share: null,
  price_total: null,
  opp_status_ndx: 1,
  offer_price_notes: null,
  description_of_location: null,
  relevant_history: null,
  opp_recommendation_ndx: 999,
  opp_recommendation_notes: null,
  opp_decision_ndx: 999,
  opp_decision_notes: null,
  opp_notes_general: null,
  opp_notes_yield: null,
  opp_notes_cost: null,
  opp_notes_availability: null,
  opp_notes_reliability: null,
  opp_notes_system_integration: null,
  opp_notes_transferability: null,
};

export const categories = [
  {
    title: "General Information",
    properties: [
      "wadt_source_name",
      "wadt_source_abbrev",
      "s_class",
      "reason_for_exclusion", // Conditionally included based on state.s_class
      "water_source",
      "district",
      "diversion_name_fav",
      "potability",
      "water_right_type",
    ],
    label: "General Information",
    field: "opp_notes_general",
  },
  {
    title: "Yield",
    properties: ["yieldhdgt_afpsh", "yieldcu_afpsh", "yield_notes"],
    label: "Yield",
    field: "opp_notes_yield",
  },

  {
    title: "Cost",
    properties: [
      "cost_per_share",
      "cost_per_af",
      "integration_cost",
      "o_and_m_cost",
    ],
    label: "Cost",
    field: "opp_notes_cost",
  },
  {
    title: "Availability",
    properties: [
      "willingness_of_ower_to_sell",
      "risk_of_price_escalation",
      "shares_total",
      "shares_not_changed",
      "shares_greeley_owned",
      "competition",
      "competition_notes",
    ],
    label: "Availability",
    field: "opp_notes_availability",
  },
  {
    title: "Reliability",
    properties: [
      "yield_under_shifted_hydro",
      "seniority_and_reliability",
      "vulterability_to_change",
    ],
    label: "Reliability",
    field: "opp_notes_reliability",
  },
  {
    title: "System Integration",
    properties: [
      "integration_into_existing_system",
      "integration_into_terry_ranch",
      "time_to_implement",
    ],
    label: "System Integration",
    field: "opp_notes_system_integration",
  },
  {
    title: "Transferability (Water Right / Administration Considerations)",
    properties: [
      "legal_complexity",
      "ditch_reservoir_company_considerations",
      "water_right_operational_flexibility",
    ],
    label: "Transferability - Water Right / Administration Considerations",
    field: "opp_notes_transferability",
  },
];
