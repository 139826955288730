import React, { useEffect } from "react";

const TomorrowWeatherWidget = () => {
  useEffect(() => {
    const scriptId = "tomorrow-sdk";

    const initWidget = () => {
      if (window.__TOMORROW__) {
        window.__TOMORROW__.renderWidget();
      }
    };

    if (document.getElementById(scriptId)) {
      initWidget();
      return;
    }

    const script = document.createElement("script");
    script.id = scriptId;
    script.src = "https://www.tomorrow.io/v1/widget/sdk/sdk.bundle.min.js";
    script.onload = initWidget;

    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="tomorrow"
      data-location-id="117586"
      data-language="EN"
      data-unit-system="IMPERIAL"
      data-skin="light"
      data-widget-type="upcoming"
      style={{ paddingBottom: "22px", position: "relative" }}
    >
      <a
        href="https://www.tomorrow.io/weather-api/"
        rel="nofollow noopener noreferrer"
        target="_blank"
        style={{
          position: "absolute",
          bottom: 0,
          transform: "translateX(-50%)",
          left: "50%",
        }}
      >
        <img
          alt="Powered by the Tomorrow.io Weather API"
          src="https://weather-website-client.tomorrow.io/img/powered-by.svg"
          width="250"
          height="18"
        />
      </a>
    </div>
  );
};

export default TomorrowWeatherWidget;
