import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useMemo, useState } from "react";
import useFetchData from "../../../hooks/useFetchData";

export const useWtpDataAdjustments = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedDataAdjustment, setSelectedDataAdjustment] = useState(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["data-overrides-wtp"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-overrides-wtp`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [parametersLookup] = useFetchData(
    "list-parameters-wtpoverrides",
    [],
    true
  );
  const parametersLookupForTable = useMemo(() => {
    let converted = {};
    if (parametersLookup?.length > 0) {
      parametersLookup.forEach((item) => {
        converted[item.measure_type_ndx] = item.parameter;
      });
    }
    return converted;
  }, [parametersLookup]);

  const [locationsLookup] = useFetchData(
    "list-locations-wtpoverrides",
    [],
    true
  );
  const locationsLookupForTable = useMemo(() => {
    let converted = {};
    if (locationsLookup?.length > 0) {
      locationsLookup.forEach((item) => {
        converted[item.location_ndx] = item.location_desc;
      });
    }
    return converted;
  }, [locationsLookup]);

  const COLUMNS = [
    {
      title: "Collection Date",
      field: "collect_date",
      type: "date",
    },
    {
      title: "Location",
      field: "location_ndx",
      lookup: locationsLookupForTable,
    },
    {
      title: "Parameter",
      field: "measure_type_ndx",
      lookup: parametersLookupForTable,
    },
    {
      title: "Override Value",
      field: "override_value",
    },
    {
      title: "Notes",
      field: "notes",
    },
  ];

  return {
    selectedDataAdjustment,
    setSelectedDataAdjustment,
    data,
    isFetching,
    error,
    refetch,
    COLUMNS,
  };
};
