import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { DEFAULT_FILTERS } from "./constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { transformDataForBarChart } from "./utils";
import useFetchData from "../../../../hooks/useFetchData";

export const useUsDsBarChart = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [graphData, setGraphData] = useState([]);
  const { waterbody } = filters;

  const handleFilterChange = useCallback((name, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [name]: value };
      return newFilters;
    });
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const url = `${process.env.REACT_APP_ENDPOINT}/api/dashboard-us-ds-graph`;
      const headers = { Authorization: `Bearer ${token}` };

      const { data } = await axios.get(url, { headers });

      return data;
    } catch (err) {
      console.error("Error fetching data:", err);
      throw err;
    }
  }, [getAccessTokenSilently]);

  const { data, error, isFetching } = useQuery(
    "dashboard-us-ds-graph",
    fetchData,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  const formattedData = useMemo(() => {
    if (!data) return [];
    return transformDataForBarChart(data, waterbody, "#2196f3");
  }, [data, waterbody]);

  useEffect(() => {
    setGraphData(formattedData);
  }, [formattedData]);

  const [waterbodiesLookup] = useFetchData("list-waterbodies-usds", [], false);

  return {
    data,
    graphData,
    isLoading: isFetching,
    error,
    handleFilterChange,
    filters,
    setFilters,
    lookups: {
      waterbodies: waterbodiesLookup,
    },
  };
};
