import React from "react";
import DwrGraphs from "./DwrGraphs";

const DwrStreamflowViewer = () => {
  const defaultWatersheds = [4, 1];
  const defaultLocations = [100270, 100271, 100596];

  const pageTitle = "Recent Streamflows";

  const endpoint = { label: "Daily", value: "display-dwrstreamflow-daily" };

  const watershedsEndpoint = "display-dwrstreamflow-lookup-watershed";
  const locationsEndpoint = "display-dwrstreamflow-lookup-locations";

  return (
    <>
      <DwrGraphs
        defaultWatersheds={defaultWatersheds}
        defaultLocations={defaultLocations}
        pageTitle={pageTitle}
        endpoint={endpoint}
        watershedsEndpoint={watershedsEndpoint}
        locationsEndpoint={locationsEndpoint}
      />
    </>
  );
};

export default DwrStreamflowViewer;
