import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ExpandMore, Error } from "@material-ui/icons";
import React from "react";

import Loader from "../../../../components/Loader";
import { useBasicWaterSourceInfo } from "./useBasicWaterSourceInfo";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getButtonIcon, getButtonText } from "./utils";
import { useSubmitData } from "../../../../hooks/useSubmitData";
import LinkedStructuresFuzzySearch from "./LinkedStructuresFuzzySearch";

function BasicWaterSourceInfo({
  selectedWaterSource,
  setSelectedWaterSource,
  refetchWaterSources,
  isEditMode = false,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    state,
    lookups,
    refetch,
    isDirty,
    handleResetState,
  } = useBasicWaterSourceInfo(selectedWaterSource);

  const refetchTopAndBottom = () => {
    refetch().then(() => refetchWaterSources());
  };

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedWaterSource,
    setSelectedWaterSource,
    state,
    "list-wadt-sources",
    "wadt_source_ndx",
    refetchTopAndBottom
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.wadt_source_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.wadt_source_ndx)
              ? "none"
              : "block",
          pageBreakBefore: "always",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">General Information</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <TextField
                required
                error={!state.wadt_source_name}
                variant="outlined"
                label="WADT Source Name"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("wadt_source_name", e.target.value)
                }
                value={state.wadt_source_name ?? ""}
              />
              {!state.wadt_source_name ? (
                <FormHelperText error>*Required field</FormHelperText>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Abbreviation"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("wadt_source_abbrev", e.target.value)
                }
                value={state.wadt_source_abbrev ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={!state.wadt_class_ndx}
                required
              >
                {!lookups?.classes.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="class">Class</InputLabel>
                    <Select
                      labelId="class"
                      id="class"
                      label="Class"
                      name="wadt_class_ndx"
                      value={state.wadt_class_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("wadt_class_ndx", e.target.value);
                      }}
                    >
                      {lookups.classes.map((option) => (
                        <MenuItem
                          key={option.wadt_class_ndx}
                          value={option.wadt_class_ndx}
                        >
                          {option.wadt_class_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {!state.wadt_class_ndx && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {state?.wadt_class_ndx === 999 && (
              <Grid
                item
                xs={12}
                style={{
                  position: "relative",
                }}
              >
                <TextField
                  variant="outlined"
                  label="Reason for No Classification"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleUpdateState("reason_for_exclusion", e.target.value)
                  }
                  value={state.reason_for_exclusion ?? ""}
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={!state.assoc_water_source_ndx.length}
                required
              >
                {!lookups?.watersources.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="water-source">
                      Location (Source Water)
                    </InputLabel>
                    <Select
                      multiple
                      labelId="water-source"
                      id="Water Source"
                      label="Location (Source Water)"
                      name="assoc_water_source_ndx"
                      value={state.assoc_water_source_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState(
                          "assoc_water_source_ndx",
                          e.target.value
                        );
                      }}
                    >
                      {lookups.watersources.map((option) => (
                        <MenuItem
                          key={option.water_source_ndx}
                          value={option.water_source_ndx}
                        >
                          {option.water_source_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {!state.assoc_water_source_ndx.length > 0 && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={!state.structure_wd}
                required
              >
                {!lookups?.waterdistricts.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="water-district">Water District</InputLabel>
                    <Select
                      labelId="water-district"
                      id="water-district"
                      label="Water District"
                      name="structure_wd"
                      value={state.structure_wd ?? ""}
                      onChange={(e) => {
                        handleUpdateState("structure_wd", e.target.value);
                      }}
                    >
                      {lookups.waterdistricts.map((option) => (
                        <MenuItem
                          key={option.structure_wd}
                          value={option.structure_wd}
                        >
                          {option.structure_wd_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {!state.structure_wd && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              xl={6}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!lookups?.structures.length > 0 ? (
                <Loader />
              ) : (
                <Box px={4} my={3} display="flex" alignItems="center">
                  <Tooltip title="If the record is not tied to a structure, it will not show up on the map.">
                    <Error
                      style={{ fontSize: 48, marginRight: 16 }}
                      color="secondary"
                    />
                  </Tooltip>
                  <div style={{ width: "100%" }}>
                    <LinkedStructuresFuzzySearch
                      options={lookups?.structures}
                      onSelect={(e) => {
                        handleUpdateState("structure_id", e);
                      }}
                    />
                    <InputLabel style={{ marginTop: "6px" }} id="structure">
                      Associated Structure
                    </InputLabel>
                    {state?.structure_id ? (
                      lookups?.structures?.find(
                        (structure) =>
                          structure.structure_id === state.structure_id
                      )?.structure_string ?? ""
                    ) : (
                      <Typography
                        color="primary"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          marginTop: "5px",
                        }}
                      >
                        ** No Alternate Owner Selected **
                      </Typography>
                    )}
                  </div>
                </Box>
              )}
            </Grid>

            {/*<Grid*/}
            {/*  item*/}
            {/*  xs={12}*/}
            {/*  md={6}*/}
            {/*  style={{*/}
            {/*    position: "relative",*/}
            {/*    display: "flex",*/}
            {/*    alignItems: "center",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <FormControl variant="outlined" style={{ width: "100%" }}>*/}
            {/*    {!lookups?.structures.length > 0 ? (*/}
            {/*      <Loader />*/}
            {/*    ) : (*/}
            {/*      <>*/}
            {/*        <InputLabel id="structure">*/}
            {/*          Water Right Structure*/}
            {/*        </InputLabel>*/}
            {/*        <Select*/}
            {/*          labelId="structure"*/}
            {/*          id="structure"*/}
            {/*          label="Water RIght Structure"*/}
            {/*          name="structure_id"*/}
            {/*          value={state.structure_id ?? ""}*/}
            {/*          onChange={(e) => {*/}
            {/*            handleUpdateState("structure_id", e.target.value);*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          {lookups.structures.map((option) => (*/}
            {/*            <MenuItem*/}
            {/*              key={option.structure_id}*/}
            {/*              value={option.structure_id}*/}
            {/*            >*/}
            {/*              {option.structure_name}*/}
            {/*            </MenuItem>*/}
            {/*          ))}*/}
            {/*        </Select>*/}
            {/*      </>*/}
            {/*    )}*/}
            {/*  </FormControl>*/}
            {/*</Grid>*/}

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={!state.assoc_supply_type_ndx.length}
                required
              >
                {!lookups?.supplyTypes.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="supply-type">
                      Potable or Non-Potable Supply
                    </InputLabel>
                    <Select
                      multiple
                      labelId="supply-type"
                      id="supply-type"
                      label="Potable or Non-Potable Supply"
                      name="assoc_supply_type_ndx"
                      value={state.assoc_supply_type_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState(
                          "assoc_supply_type_ndx",
                          e.target.value
                        );
                      }}
                    >
                      {lookups.supplyTypes.map((option) => (
                        <MenuItem
                          key={option.supply_type_ndx}
                          value={option.supply_type_ndx}
                        >
                          {option.supply_type_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {!state.assoc_supply_type_ndx.length > 0 && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={!state.assoc_wr_type_ndx.length}
                required
              >
                {!lookups?.waterrightTypes.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="waterright-types">
                      Type(s) of Water Rights
                    </InputLabel>
                    <Select
                      multiple
                      labelId="waterright-types"
                      id="waterright-types"
                      label="Type(s) of Water Rights"
                      name="assoc_wr_type_ndx"
                      value={state.assoc_wr_type_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("assoc_wr_type_ndx", e.target.value);
                      }}
                    >
                      {lookups.waterrightTypes.map((option) => (
                        <MenuItem
                          key={option.wr_type_ndx}
                          value={option.wr_type_ndx}
                        >
                          {option.wr_type_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {!state.assoc_wr_type_ndx.length > 0 && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {state?.wadt_source_ndx && (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={3}
                  style={{
                    position: "relative",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl style={{ width: "100%" }}>
                      <KeyboardDatePicker
                        disabled
                        format="LLLL do, yyyy"
                        inputVariant="outlined"
                        autoOk
                        id="created-on"
                        label="Created On"
                        value={state?.created_timestamp}
                        onChange={(date) => {
                          handleUpdateState("created_timestamp", date);
                        }}
                        InputAdornmentProps={{
                          "aria-label": "change created date",
                        }}
                        InputProps={{
                          style: {
                            color: "rgba(0,0,0,1)",
                          },
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={3}
                  style={{
                    position: "relative",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl style={{ width: "100%" }}>
                      <KeyboardDatePicker
                        disabled
                        format="LLLL do, yyyy"
                        inputVariant="outlined"
                        autoOk
                        id="updated-on"
                        label="Updated On"
                        value={state?.last_updated_timestamp}
                        onChange={(date) => {
                          handleUpdateState("last_updated_timestamp", date);
                        }}
                        InputAdornmentProps={{
                          "aria-label": "change updated date",
                        }}
                        InputProps={{
                          style: {
                            color: "rgba(0,0,0,1)",
                          },
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.wadt_source_ndx)
              ? "none"
              : "block",
          pageBreakBefore: "always",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Yield</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  type="number"
                  variant="outlined"
                  label="Delivery Yield, AF per Share"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      handleUpdateState("yieldhdgt_afpsh", null);
                    } else {
                      handleUpdateState("yieldhdgt_afpsh", +e.target.value);
                    }
                  }}
                  value={state?.yieldhdgt_afpsh ?? ""}
                  InputProps={{
                    inputProps: {
                      onKeyPress: (e) => {
                        if (e.key === "e") {
                          e.preventDefault();
                        }
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  type="number"
                  variant="outlined"
                  label="Consumptive Use Yield, AF per Share"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      handleUpdateState("yieldcu_afpsh", null);
                    } else {
                      handleUpdateState("yieldcu_afpsh", +e.target.value);
                    }
                  }}
                  value={state?.yieldcu_afpsh ?? ""}
                  InputProps={{
                    inputProps: {
                      onKeyPress: (e) => {
                        if (e.key === "e") {
                          e.preventDefault();
                        }
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Yield Notes"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("yield_notes", e.target.value)
                }
                value={state.yield_notes ?? ""}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.wadt_source_ndx)
              ? "none"
              : "block",
          pageBreakBefore: "always",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Cost</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  type="number"
                  variant="outlined"
                  label="Purchase Price per Share"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      handleUpdateState("cost_per_share", null);
                    } else {
                      handleUpdateState("cost_per_share", +e.target.value);
                    }
                  }}
                  value={state?.cost_per_share ?? ""}
                  InputProps={{
                    inputProps: {
                      onKeyPress: (e) => {
                        if (e.key === "e") {
                          e.preventDefault();
                        }
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  type="number"
                  variant="outlined"
                  label="Purchase Price per AF"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      handleUpdateState("cost_per_af", null);
                    } else {
                      handleUpdateState("cost_per_af", +e.target.value);
                    }
                  }}
                  value={state?.cost_per_af ?? ""}
                  InputProps={{
                    inputProps: {
                      onKeyPress: (e) => {
                        if (e.key === "e") {
                          e.preventDefault();
                        }
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Integration Cost"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("integration_cost", e.target.value)
                }
                value={state.integration_cost ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Operating and Maintenance Cost"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("o_and_m_cost", e.target.value)
                }
                value={state.o_and_m_cost ?? ""}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.wadt_source_ndx)
              ? "none"
              : "block",
          pageBreakBefore: "always",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Availability</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Willingness of Owners to Sell"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState(
                    "willingness_of_ower_to_sell",
                    e.target.value
                  )
                }
                value={state.willingness_of_ower_to_sell ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Risk of Price Escalation"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("risk_of_price_escalation", e.target.value)
                }
                value={state.risk_of_price_escalation ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  type="number"
                  variant="outlined"
                  label="Total Shares"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      handleUpdateState("shares_total", null);
                    } else {
                      handleUpdateState("shares_total", +e.target.value);
                    }
                  }}
                  value={state?.shares_total ?? ""}
                  InputProps={{
                    inputProps: {
                      onKeyPress: (e) => {
                        if (e.key === "e") {
                          e.preventDefault();
                        }
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  type="number"
                  variant="outlined"
                  label="# Shares not Yet Changed"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      handleUpdateState("shares_not_changed", null);
                    } else {
                      handleUpdateState("shares_not_changed", +e.target.value);
                    }
                  }}
                  value={state?.shares_not_changed ?? ""}
                  InputProps={{
                    inputProps: {
                      onKeyPress: (e) => {
                        if (e.key === "e") {
                          e.preventDefault();
                        }
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  type="number"
                  variant="outlined"
                  label="# Shares Owned by Greeley"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      handleUpdateState("shares_greeley_owned", null);
                    } else {
                      handleUpdateState(
                        "shares_greeley_owned",
                        +e.target.value
                      );
                    }
                  }}
                  value={state?.shares_greeley_owned ?? ""}
                  InputProps={{
                    inputProps: {
                      onKeyPress: (e) => {
                        if (e.key === "e") {
                          e.preventDefault();
                        }
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!lookups?.competition.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="class">Class</InputLabel>
                    <Select
                      labelId="class"
                      id="class"
                      label="Class"
                      name="competition_level_ndx"
                      value={state.competition_level_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState(
                          "competition_level_ndx",
                          e.target.value
                        );
                      }}
                    >
                      {lookups.competition.map((option) => (
                        <MenuItem
                          key={option.competition_level_ndx}
                          value={option.competition_level_ndx}
                        >
                          {option.competition_level_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            {/*--*/}
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Availability and Competition Notes"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState(
                    "availability_and_competition_notes",
                    e.target.value
                  )
                }
                value={state.availability_and_competition_notes ?? ""}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.wadt_source_ndx)
              ? "none"
              : "block",
          pageBreakBefore: "always",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Reliability</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Potential Yield Under Shifted Hydrograph"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("yield_under_shifted_hydro", e.target.value)
                }
                value={state.yield_under_shifted_hydro ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Seniority and Reliability"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("seniority_and_reliability", e.target.value)
                }
                value={state.seniority_and_reliability ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Vulnerability to Change"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("vulerability_to_change", e.target.value)
                }
                value={state.vulerability_to_change ?? ""}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.wadt_source_ndx)
              ? "none"
              : "block",
          pageBreakBefore: "always",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">System Integration</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Integration into Existing System"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState(
                    "integration_into_existing_system",
                    e.target.value
                  )
                }
                value={state.integration_into_existing_system ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Integration into Terry Ranch"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState(
                    "integration_into_terry_ranch",
                    e.target.value
                  )
                }
                value={state.integration_into_terry_ranch ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Time to Implement"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("time_to_implement", e.target.value)
                }
                value={state.time_to_implement ?? ""}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.wadt_source_ndx)
              ? "none"
              : "block",
          pageBreakBefore: "always",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">
              Transferability (Water Right / Administration Considerations)
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Legal Complexity"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState("legal_complexity", e.target.value)
                }
                value={state.legal_complexity ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Ditch/Reservoir Company Considerations"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState(
                    "ditch_reservoir_company_considerations",
                    e.target.value
                  )
                }
                value={state.ditch_reservoir_company_considerations ?? ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Water Right Operational Flexibility"
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleUpdateState(
                    "water_right_operational_flexibility",
                    e.target.value
                  )
                }
                value={state.water_right_operational_flexibility ?? ""}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <AccordionActions>
        <Button size="small" onClick={handleResetState}>
          Reset
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
          disabled={
            !isDirty ||
            !state.wadt_source_name ||
            !state.wadt_class_ndx ||
            !state.assoc_water_source_ndx.length ||
            !state.structure_wd ||
            !state.assoc_supply_type_ndx.length ||
            !state.assoc_wr_type_ndx.length ||
            isFormSubmitting
          }
          startIcon={getButtonIcon(isFormSubmitting)}
          style={{ width: "100px" }}
        >
          {getButtonText(isFormSubmitting, selectedWaterSource === 0)}
        </Button>
      </AccordionActions>
    </>
  );
}

export default BasicWaterSourceInfo;
