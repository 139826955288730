import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useState } from "react";

export const useWaterSources = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedWaterSource, setSelectedWaterSource] = useState(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["ui-report-sources"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-report-sources`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const WATER_SOURCES_TABLE_COLUMNS = [
    {
      title: "WADT Source Name",
      field: "wadt_source_name",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "WADT Class",
      field: "s_class",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Water Source",
      field: "water_source",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Ditch/System Source",
      field: "diversion_name_fav",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
  ];

  return {
    selectedWaterSource,
    setSelectedWaterSource,
    data,
    isFetching,
    error,
    refetch,
    WATER_SOURCES_TABLE_COLUMNS,
  };
};
