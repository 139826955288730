import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { Paper } from "@material-ui/core";
import "./styles.css";

import MapWadt from "./mapWadt";
import ZoomInfo from "./controls/zoomInfo";
import { useWadtMap } from "./hooks/useWadtMap";
import useWadtFilters from "./hooks/useWadtFilters";

import DisclaimerDialog from "./components/DisclaimerDialog";
import MeasurementsPopup from "../../components/map/components/MeasurementsPopup";

import PrintReportDialog from "./components/PrintReportDialog";
import { useReactToPrint } from "react-to-print";
import PrintMapFormat from "./components/PrintMapFormat";
import SplitButton from "../../components/SplitButton";
import MeasurementsControl from "./controls/MeasurementsControl";

import WadtFiltersBar from "./components/WadtFiltersBar";
import WadtSearch from "./filters/wadtSearch";
import WadtMainControl from "./controls/wadtMainControl";
import useWadtSources from "./hooks/useWadtSources";
import { polygon } from "@turf/helpers";
import center from "@turf/center";
import { INIT_MAP_CONFIG } from "./wadtConstants";

const FiltersBarRoot = styled(Paper)`
  height: 78px;
  align-items: center;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(6)}px;
  padding: 12px 16px 12px 32px;
  overflow-y: scroll;
`;

const FiltersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex: 1 1 0;
`;

const FiltersSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 100;
`;

export function getPolygonCenter(coordinates) {
  // Check if the input is a single polygon or an array of polygons
  const isSinglePolygon =
    Array.isArray(coordinates[0][0]) &&
    typeof coordinates[0][0][0] === "number";

  if (isSinglePolygon) {
    // Handle single polygon
    const poly = polygon(coordinates);
    const centerCoords = center(poly).geometry.coordinates;
    return centerCoords;
  } else {
    // Handle array of polygons
    let totalLat = 0;
    let totalLon = 0;

    coordinates.forEach((coords) => {
      const poly = polygon(coords);
      const centerCoords = center(poly).geometry.coordinates;
      totalLat += centerCoords[1];
      totalLon += centerCoords[0];
    });

    const averageLat = totalLat / coordinates.length;
    const averageLon = totalLon / coordinates.length;

    return [averageLon, averageLat];
  }
}

const WadtMap = () => {
  const mapContainer = useRef(null);
  const { sources } = useWadtSources();
  const {
    activeBasemap,
    basemaps,
    layers,
    map,
    zoomLevel,
    searchRadiusBuffers,
    resetSearchRadiusBuffers,
    handleClearSearchRadiusBuffers,
    handleEnableSearchRadiusControl,
    updateSearchRadiusBuffers,
    updateLayerFilters,
    updateLayerStyles,
    updateLayerVisibility,
    updateLayerOpacity,
    updateBasemap,
    measurementsVisible,
    handleClearMeasurements,
    setMeasurementsVisible,
    polygonRef,
    radiusRef,
    pointRef,
    lineRef,
    measurementsContainerRef,
    eventsRegistered,
    isMapLoaded,
  } = useWadtMap(mapContainer, INIT_MAP_CONFIG, sources);
  const {
    filterValues,
    handleFilterValues,
    handleSelectAll,
    handleSelectNone,
  } = useWadtFilters({ onFilterChange: updateLayerFilters });

  const printRef = useRef();
  const [printReportDialogOpen, setPrintReportDialogOpen] = useState(false);
  const [title, setTitle] = useState("");
  const handlePrintMapClick = useReactToPrint({
    content: () => printRef.current,
  });

  const handleSavePNG = () => {
    const a = document.createElement("a");
    a.href = map.getCanvas().toDataURL();
    a.download = "map.png";
    document.body.appendChild(a);
    a.click();
  };

  const splitButtonOptions = ["Print PDF", "Save PNG"];
  const handleSplitButtonClick = (index) => {
    if (index === 0) {
      setPrintReportDialogOpen(true);
    } else if (index === 1) {
      handleSavePNG();
    }
  };

  const handleSearchSelect = (result) => {
    if (result?.wadt_source_geometry?.coordinates) {
      const center = getPolygonCenter(
        result?.wadt_source_geometry?.coordinates
      );
      map?.flyTo({ center: center, zoom: 11 });
    }
    if (result?.location_geometry?.coordinates) {
      map?.flyTo({ center: result?.location_geometry?.coordinates, zoom: 16 });
    }
  };

  return (
    <>
      {process.env.NODE_ENV !== "development" && <DisclaimerDialog />}

      {process.env.NODE_ENV === "development" && (
        <ZoomInfo zoomLevel={zoomLevel} />
      )}

      <FiltersBarRoot>
        <FiltersContainer>
          <FiltersSectionRow>
            <WadtSearch onSelect={handleSearchSelect} sources={sources} />
          </FiltersSectionRow>
        </FiltersContainer>
        <WadtFiltersBar
          filterValues={filterValues}
          handleFilterValues={handleFilterValues}
          handleSelectAll={handleSelectAll}
          handleSelectNone={handleSelectNone}
          updateLayerStyles={updateLayerStyles}
          isMapLoaded={isMapLoaded}
        />

        <FiltersSection>
          <FiltersContainer>
            <>
              <SplitButton
                options={splitButtonOptions}
                handleExportClick={handleSplitButtonClick}
                width="125px"
              />

              <PrintReportDialog
                downloadCallback={handlePrintMapClick}
                setPrintReportDialogOpen={setPrintReportDialogOpen}
                printReportDialogOpen={printReportDialogOpen}
                title={title}
                setTitle={setTitle}
              />
            </>
          </FiltersContainer>
        </FiltersSection>
      </FiltersBarRoot>

      <MapWadt ref={mapContainer}>
        <MeasurementsPopup
          measurementsContainerRef={measurementsContainerRef}
          radiusRef={radiusRef}
          polygonRef={polygonRef}
          pointRef={pointRef}
          lineRef={lineRef}
          onHide={() => setMeasurementsVisible(false)}
          onClear={handleClearMeasurements}
        />
        {eventsRegistered && (
          <WadtMainControl
            activeBasemap={activeBasemap}
            basemaps={basemaps}
            bufferValues={searchRadiusBuffers}
            layers={layers}
            onBasemapChange={updateBasemap}
            onBufferValuesChange={updateSearchRadiusBuffers}
            onClearBuffers={handleClearSearchRadiusBuffers}
            onEnableSearchRadiusControl={handleEnableSearchRadiusControl}
            onLayerChange={updateLayerVisibility}
            onOpacityChange={updateLayerOpacity}
            onResetBuffers={resetSearchRadiusBuffers}
          />
        )}

        {!measurementsVisible && (
          <MeasurementsControl
            open={measurementsVisible}
            onToggle={() => setMeasurementsVisible(!measurementsVisible)}
            right={49}
            bottom={30}
          />
        )}
      </MapWadt>

      {eventsRegistered && printReportDialogOpen && (
        <span
          style={{
            display: "none",
            width: "100%",
          }}
        >
          <PrintMapFormat
            ref={printRef}
            title={title}
            mapImg={map.getCanvas().toDataURL("image/png")}
            map={map}
          />
        </span>
      )}
    </>
  );
};

export default WadtMap;
