import { useEffect, useState } from "react";
import { scaleOrdinal } from "d3-scale";
import { schemePaired } from "d3-scale-chromatic";
import { useQuery } from "react-query";
import axios from "axios";
import { INIT_STYLE_VALUES } from "../../wadtConstants";
import { useAuth0 } from "@auth0/auth0-react";

const useWadtLayerStyles = ({ onLayerStyleChange, isMapLoaded }) => {
  const [styleValues, setStyleValues] = useState(INIT_STYLE_VALUES);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const buildScale = (values) => {
    const scale = scaleOrdinal(schemePaired);
    return values.reduce((acc, val) => {
      acc.push([val]);
      acc.push(scale(val));
      return acc;
    }, []);
  };

  const { data } = useQuery(
    ["wadtFilterData2", isAuthenticated],
    async () => {
      let headers = {};
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        headers = { Authorization: `Bearer ${token}` };
      }
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/wadt-map/filters`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setStyleValues((prevState) => ({
        ...prevState,
        classes: {
          loaded: true,
          ...prevState.classes,
          paint: {
            "fill-color": [
              "match",
              ["get", "wadt_class_name"],
              ["Class 1 - Aggressive Acquisition"],
              "#A6CEE3",
              ["Class 2 - Active Acquisition"],
              "#1F78B4",
              ["Class 3 - Passive Acquisition"],
              "#B2DF8A",
              ["Unclassified"],
              "#FB9A99",
              "#fff",
            ],
          },
        },

        waterSources: {
          ...prevState.waterSources,
          paint: {
            "fill-color": [
              "match",
              ["get", "water_src_fav"],
              ...buildScale(data.waterSources.map((item) => item.display)),
              "#fff",
            ],
            // "circle-stroke-width": [
            //   "case",
            //   [">", ["length", ["get", "water_src"]], 1],
            //   5,
            //   2,
            // ],
            // "circle-stroke-color": [
            //   "case",
            //   [">", ["length", ["get", "water_src"]], 1],
            //   "white",
            //   "black",
            // ],
          },
        },

        ownership: {
          ...prevState.ownership,
          paint: {
            "fill-color": [
              "match",
              ["get", "ownership"],
              ...buildScale(data.ownership.map((item) => item.display)),
              "#fff",
            ],
          },
        },
        availability: {
          ...prevState.availability,
          paint: {
            "fill-color": [
              "match",
              ["get", "availability"],
              ...buildScale(data.availability.map((item) => item.display)),
              "#fff",
            ],
          },
        },
      }));
    }
  }, [data]);

  const [activeStyle, setActiveStyle] = useState(styleValues.classes);
  const styleOptions = Object.entries(styleValues).map(([key, value]) => ({
    display: value.name,
    value: key,
  }));

  const handleActiveStyle = (name) => {
    setActiveStyle(styleValues[name]);
    onLayerStyleChange(styleValues[name]);
  };

  useEffect(() => {
    if (isMapLoaded && styleValues.classes.loaded) {
      handleActiveStyle("classes");
    }
  }, [styleValues, isMapLoaded]); //eslint-disable-line

  return {
    activeStyle,
    handleActiveStyle,
    styleOptions,
    styleValues,
  };
};

export default useWadtLayerStyles;
