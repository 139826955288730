import React, { useMemo, useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Typography as MuiTypography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { useApp } from "../../../AppProvider";
import { useClimateCenter } from "./useClimateCenter";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../../components/Loader";
import Table from "../../../components/Table";
import Button from "@material-ui/core/Button";
import { dateFormatter } from "./utils";
import { Alert } from "@material-ui/lab";
import { ISO_DATE_FORMAT } from "./constants";
import BarChart from "./BarChart";
import LineChart from "./LineChart";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const OptionsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TableWrapper = styled(Box)`
  width: 100%;
`;

const ClimateCenter = () => {
  const { currentUser } = useApp();
  const scrollTargetRef = useRef(null);

  const {
    yearsLookup,
    dataDaily,
    dataMonthly,
    dataTable,
    error,
    isLoading,
    handleFilterChange,
    filters,
  } = useClimateCenter();

  const scrollToTarget = () => {
    scrollTargetRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const COLUMNS = useMemo(
    () => [
      {
        title: "Collection Date",
        field: "collect_date",
        render: (rowData) =>
          dateFormatter(rowData.collect_date, ISO_DATE_FORMAT),
      },
      { title: "Daily Average Temperature", field: "daily_avg_temp" },
      {
        title: "Daily Normal Temperature",
        field: "daily_normal_temp",
      },
      { title: "Temperature Units", field: "temp_units" },
      {
        title: "Daily Precipitation",
        field: "daily_precip",
      },
      { title: "YTD Accumulated Precipitation", field: "ytd_accum_precip" },
      {
        title: "YTD Normal Precipitation",
        field: "ytd_normal_precip",
      },
      { title: "Precipitation Units", field: "precip_units" },
    ],
    [dataTable] //eslint-disable-line
  );

  if (error) return "An error has occurred: " + error?.error?.message;
  return (
    <>
      <Helmet title="Climate Center" />
      <Typography variant="h3" gutterBottom display="inline">
        Climate Center
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={currentUser?.home || "/"}>
          Dashboard
        </Link>
        <Typography>Climate Center</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="options"
          id="options"
        >
          <Typography variant="h4">Options</Typography>
        </AccordionSummary>
        <Paper>
          <AccordionDetails>
            <OptionsContainer>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "200px" }}
              >
                {!yearsLookup.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="year">Year</InputLabel>
                    <Select
                      labelId="year"
                      id="year"
                      label="Year"
                      name="year"
                      value={filters.year ?? ""}
                      onChange={(e) => {
                        handleFilterChange("year", e.target.value);
                      }}
                    >
                      {yearsLookup.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>

              <span
                style={{
                  marginLeft: "auto",
                  width: "200px",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={scrollToTarget}
                  disabled={!dataTable?.length || isLoading}
                >
                  Scroll to Table
                </Button>
              </span>
            </OptionsContainer>
          </AccordionDetails>
        </Paper>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="options"
          id="options"
        >
          <Typography variant="h4">Climate Center</Typography>
        </AccordionSummary>
        <Paper>
          {isLoading ? (
            <Box mb={5}>
              <Loader />
            </Box>
          ) : (
            <AccordionDetails style={{ flexDirection: "column" }}>
              {!dataMonthly?.length ? (
                <Alert mb={5} severity="info">
                  <strong>No monthly records</strong> available for this query.
                  Please try again.
                </Alert>
              ) : (
                <BarChart data={dataMonthly} />
              )}
              {!dataDaily?.series?.length ? (
                <Alert mb={5} severity="info">
                  <strong>No daily records</strong> available for this query.
                  Please try again.
                </Alert>
              ) : (
                <LineChart data={dataDaily} />
              )}
            </AccordionDetails>
          )}
        </Paper>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="table-content"
          id="table-header"
        >
          <Typography variant="h4">Table</Typography>
        </AccordionSummary>
        <Paper>
          <AccordionDetails>
            <TableWrapper ref={scrollTargetRef}>
              <Table
                isLoading={isLoading}
                label="Climate Center"
                columns={COLUMNS}
                data={dataTable}
                height="400px"
                options={{ search: false, width: "100%" }}
                pageSize={60}
              />
            </TableWrapper>
          </AccordionDetails>
        </Paper>
      </Accordion>
    </>
  );
};
export default ClimateCenter;
